import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { projectApi } from '../api/project.api'
import { IProject } from '../../types/project.type'

export const initialState: {project: IProject} = {
  project: {
    id: '',
    name: '', 
    country: '',
    city:'',
    address: '',
    zipCode: '',
    mainPhoto: '',
    accountId: '',
    projectNumber: '',
    status: 'Active',
    lastOpenedBy: '',
    lastOpenedAt: '',
    createdAt: '',
    updatedAt: '',
  },
}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setActiveProject: (state, action: PayloadAction<IProject>) => {
      state.project = action.payload
    },

  },
    extraReducers: (builder) => {
    builder.addMatcher(projectApi.endpoints?.getProject.matchFulfilled, (state, action) => {
      state.project = action.payload
    })
  },
})

export const { setActiveProject } = projectSlice.actions

export default projectSlice.reducer
