import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { authApi } from './api/auth.api'
import { accountApi } from './api/account.api'
import { messageApi } from './api/message.api'
import { publicApi } from './api/pubic.api'
import { appStateSlice } from './slices/appState.slice'
import { onboardingSlice } from './slices/onboardingSlice'
import { conversationApi } from './api/conversation.api'
import { uploadsApi } from './api/uploads.api'
import { usersApi } from './api/users.api'
import { usersSlice } from './slices/users.slice'
import { ticketApi } from './api/ticket.api'
import { ticketSlice } from './slices/ticket.slice'
import { profileApi } from './api/profile.api'
// import { profileSlice } from './slices/profile.slice'
import { accountsApi } from './api/admin/accounts.api'
import { accountSlice } from './slices/account.slice'
import { agentApi } from './api/agent.api'
import { agentsSlice } from './slices/agents.slice'
import { storageApi } from './api/storage.api'
import { storageSlice } from './slices/storage.slice'
import { chatSlice } from './slices/chat.slice'
import { integrationApi } from './api/integration.api'
import { documentsApi } from './api/document.api'
import { projectApi } from './api/project.api'
import { observationApi } from './api/observation.api'
import { projectSlice } from './slices/project.slice'
import { observationsSlice } from './slices/observation.slice'

const persistChatConfig = {
  key: chatSlice.name,
  storage,
  whitelist: ['activeConversationId', 'activeChatAgentId'],
}

const persistAgentConfig = {
  key: agentsSlice.name,
  storage,
  whitelist: ['currentAgentId'],
}

const persistStorageConfig = {
  key: storageSlice.name,
  storage,
  whitelist: ['currentStorageId'],
}

const persistAppConfig = {
  key: appStateSlice.name,
  storage,
  whitelist: ['user'],
}

const persistedChatReducer = persistReducer(persistChatConfig, chatSlice.reducer)
const persistedAgentReducer = persistReducer(persistAgentConfig, agentsSlice.reducer)
const persistedStorageReducer = persistReducer(persistStorageConfig, storageSlice.reducer)
const persistedAppReducer = persistReducer(persistAppConfig, appStateSlice.reducer)

export const store = configureStore({
  reducer: {
    [accountSlice.name]: accountSlice.reducer,
    [appStateSlice.name]: persistedAppReducer,
    [onboardingSlice.name]: onboardingSlice.reducer,
    [usersSlice.name]: usersSlice.reducer,
    [ticketSlice.name]: ticketSlice.reducer,
    [agentsSlice.name]: persistedAgentReducer,
    [storageSlice.name]: persistedStorageReducer,
    [chatSlice.name]: persistedChatReducer,
    [projectSlice.name]: projectSlice.reducer,
    [observationsSlice.name]: observationsSlice.reducer,
    // API reducers
    [authApi.reducerPath]: authApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [accountsApi.reducerPath]: accountsApi.reducer,
    [messageApi.reducerPath]: messageApi.reducer,
    [publicApi.reducerPath]: publicApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [ticketApi.reducerPath]: ticketApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [agentApi.reducerPath]: agentApi.reducer,
    [storageApi.reducerPath]: storageApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [conversationApi.reducerPath]: conversationApi.reducer,
    [uploadsApi.reducerPath]: uploadsApi.reducer,
    [integrationApi.reducerPath]: integrationApi.reducer,
    [documentsApi.reducerPath]: documentsApi.reducer,
    [observationApi.reducerPath]: observationApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(projectApi.middleware)
      .concat(publicApi.middleware)
      .concat(authApi.middleware)
      .concat(profileApi.middleware)
      .concat(usersApi.middleware)
      .concat(ticketApi.middleware)
      .concat(accountApi.middleware)
      .concat(messageApi.middleware)
      .concat(accountsApi.middleware)
      .concat(conversationApi.middleware)
      .concat(agentApi.middleware)
      .concat(storageApi.middleware)
      .concat(uploadsApi.middleware)
      .concat(integrationApi.middleware)
      .concat(documentsApi.middleware)
      .concat(observationApi.middleware),
})

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
