import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Tabs, TabsProps } from 'antd'
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useGetFilterObservationsQuery } from '../../../store/api/observation.api'
import { useGetProjectQuery } from '../../../store/api/project.api'
import { StatusTitle } from '../../../types/observation.type'
import './Observations.less'
import dayjs from 'dayjs'
import Icons from '../../../components/UI/Icons/Icons'

const { RangePicker } = DatePicker
const dateFormat = 'YYYY/MM/DD/'

const statusTitle = {
  'Not addressed': '#FF0D31',
  'In progress': '#FFBF00',
  Addressed: '#2C875D',
}

export default function Observations() {
  const navigate = useNavigate()
  const location = useLocation()
  const { projectId } = useParams()
  const [searchParams] = useSearchParams()

  const from = searchParams.get('from')
  const to = searchParams.get('to')

  const [selectedDates, setSelectedDates] = useState<any>(
    from && to ? [dayjs(from, dateFormat), dayjs(to, dateFormat)] : undefined,
  )
  const { data: project } = useGetProjectQuery(projectId as string, {
    skip: !projectId,
  })
  const { data: observations } = useGetFilterObservationsQuery({
    projectId: project?.id || '',
  })

  const tabItem = ({ item, count }: { item: string; count: number }) => (
    <div className="tabItem">
      <div className="dot" style={{ backgroundColor: statusTitle[item as StatusTitle] }} />
      <p className="tabItem-text">{item}</p>
      <span className="count">{count}</span>
    </div>
  )

  const items: TabsProps['items'] = [
    {
      key: 'addressed',
      label: tabItem({ item: 'Addressed', count: observations?.addressedCount ?? 0 }),
    },
    {
      key: 'inProgress',
      label: tabItem({ item: 'In progress', count: observations?.inProgressCount ?? 0 }),
    },
    {
      key: 'notAddressed',
      label: tabItem({ item: 'Not addressed', count: observations?.notAddressedCount ?? 0 }),
    },
  ]

  const handleTabChange = (key: string) => {
    // const queryParams = location.search
    navigate(`${key}`, { replace: true })
  }

  const onCalendarChange = (dates: any) => {
    const { pathname } = location
    if (!dates || !dates[0] || !dates[1]) {
      navigate(pathname, { replace: true })
    } else {
      const from = dates[0]?.format(dateFormat) || ''
      const to = dates[1]?.format(dateFormat) || ''
      navigate(`?from=${from}&to=${to}`)
    }
  }

  const goBack = () => {
    navigate(`/projects/${projectId}`)
  }

  useEffect(() => {
    if (from && to) {
      setSelectedDates([dayjs(from, dateFormat), dayjs(to, dateFormat)])
    } else {
      setSelectedDates(undefined)
    }
  }, [from, to])

  return (
    <div>
      <div style={{ marginTop: 10 }}>
        <Button type="ghost" className="button-back" onClick={() => goBack()}>
          <span className="span-arrow">
            <Icons name="arrowDown" />
          </span>
          <h2>Observation</h2>
        </Button>
      </div>
      <div style={{ padding: '0px 20px' }}>
        <div className="tabBox">
          <Tabs
            activeKey={location.pathname.split('/').pop() || ''}
            items={items}
            onChange={handleTabChange}
            destroyInactiveTabPane
          />
          <div className="observations-from">
            <p>Observations from:</p>
            <RangePicker size="large" value={selectedDates} onCalendarChange={onCalendarChange} format={dateFormat} />
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  )
}
