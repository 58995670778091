import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, InputRef, Popconfirm, Select, Space, Table } from 'antd'
import { SearchOutlined, UserAddOutlined } from '@ant-design/icons'
import {
  CreateAccountMutationBody,
  useCreateAccountMutation,
  useGetAccountsMutation,
} from '../../store/api/admin/accounts.api'
import './accounts.less'
import { LocalStorage } from 'ts-localstorage'
import { ADMIN_ACCOUNT_ID, ADMIN_USER_ID } from '../../config/consts'
import { ColumnType } from 'antd/es/table'
import type { ColumnsType } from 'antd/es/table/interface'
import { useAccountUpdateMutation, useDeleteAccountMutation } from '../../store/api/account.api'
import { useSuccessMessage } from '../../hooks/useSuccessMessage'
import { AccountStatusEnum } from '../../types/account.type'
import AiHelperHeader from '../../components/AIHelperHeader/aiHelperHeader'
import { useTranslation } from 'react-i18next'
import InviteCompanyModal from './components/InviteCompanyModal'
import { formatDate } from '../../helpers/date'

interface DataType {
  id: string
  createdAt: string
  updatedAt: string
  name: string
  status: string
  email: string
  conversations: number
}

type DataIndex = keyof DataType

const escapeRegExp = (string: string) => string.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')

const Accounts = () => {
  const { t } = useTranslation('accounts')
  const searchInput = useRef<InputRef>(null)

  const [emailSearching, setEmailSearching] = useState('')
  const [nameSearching, setNameSearching] = useState('')
  const [isAddCompanyModalOpen, setIsAddCompanyModalOpen] = useState(false)

  const [getAccounts, { data: dataSource }] = useGetAccountsMutation()
  const [accountUpdate, accountUpdateMutationResult] = useAccountUpdateMutation()
  const [createAccount, createAccountMutationResult] = useCreateAccountMutation()
  const [deleteAccount, deleteAccountMutationResult] = useDeleteAccountMutation()

  useSuccessMessage(t('messages.success'), accountUpdateMutationResult.isSuccess)
  useSuccessMessage(t('messages.invitation'), createAccountMutationResult.isSuccess)

  useEffect(() => {
    getAccounts({ page: 1, limit: 10 })
  }, [])

  useEffect(() => {
    if (createAccountMutationResult.isSuccess) {
      setIsAddCompanyModalOpen(false)
      getAccounts({ page: 1, limit: 10 })
    }
  }, [createAccountMutationResult.status])

  useEffect(() => {
    if (deleteAccountMutationResult.isSuccess) {
      getAccounts({ page: 1, limit: 10 })
    }
  }, [deleteAccountMutationResult.status])

  const handleStatusChange = (id: string, status: AccountStatusEnum) => {
    accountUpdate({ id, status })
  }

  const createAccountSubmit = (values: CreateAccountMutationBody) => {
    createAccount(values)
  }

  const handleSearch = (selectedKeys: string[], close: () => void, dataIndex: string) => {
    close()

    if (dataIndex === 'name') {
      setNameSearching(selectedKeys[0])
      getAccounts({
        page: 1,
        limit: 10,
        userMatch: emailSearching ? { email: { $regex: escapeRegExp(emailSearching), $options: 'i' } } : undefined,
        match: selectedKeys[0] ? { name: { $regex: escapeRegExp(selectedKeys[0]), $options: 'i' } } : undefined,
      })
    } else if (dataIndex === 'email') {
      setEmailSearching(selectedKeys[0])
      getAccounts({
        page: 1,
        limit: 10,
        userMatch: selectedKeys[0] ? { email: { $regex: escapeRegExp(selectedKeys[0]), $options: 'i' } } : undefined,
        match: nameSearching ? { name: { $regex: escapeRegExp(nameSearching), $options: 'i' } } : undefined,
      })
    } else if (dataIndex === 'id') {
      setEmailSearching(selectedKeys[0])
      getAccounts({
        page: 1,
        limit: 10,
        match: { id: selectedKeys[0] },
      })
    }
  }

  const handleReset = (clearFilters: () => void, dataIndex: string) => {
    clearFilters()

    if (dataIndex === 'name') {
      setNameSearching('')
      getAccounts({
        page: 1,
        limit: 10,
        userMatch: emailSearching ? { email: { $regex: escapeRegExp(emailSearching), $options: 'i' } } : undefined,
      })
    } else if (dataIndex === 'email') {
      setEmailSearching('')
      getAccounts({
        page: 1,
        limit: 10,
        match: nameSearching ? { name: { $regex: escapeRegExp(nameSearching), $options: 'i' } } : undefined,
      })
    }
  }

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={t('searchPlaceholder', { dataIndex })}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => handleSearch(selectedKeys as string[], close, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], close, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t('searchButton')}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            {t('resetButton')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    render: (text) => text,
  })

  const columns: ColumnsType<DataType> = [
    {
      title: t('accountName'),
      dataIndex: 'name',
      key: 'accountName',
      ...getColumnSearchProps('name'),
    },
    {
      title: t('customerName'),
      dataIndex: ['users', 0, 'name'],
      key: 'userName',
    },
    {
      title: t('customerEmail'),
      dataIndex: ['users', 0, 'email'],
      key: 'userEmail',
      ...getColumnSearchProps('email'),
    },
    {
      title: t('accountStatus'),
      dataIndex: 'status',
      key: 'accountStatus',
      render: (status: AccountStatusEnum, record: any) => (
        <Select
          defaultValue={status}
          onChange={(v: AccountStatusEnum) => handleStatusChange(record.id, v)}
          options={Object.entries(AccountStatusEnum).map(([label, value]) => ({ label, value }))}
        />
      ),
    },
    {
      title: t('accountId'),
      dataIndex: 'id',
      key: 'accountId',
      ...getColumnSearchProps('id'),
    },
    {
      title: t('dateCreated'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value: string) => formatDate(value),
    },
    {
      title: t('actions'),
      dataIndex: '',
      render: (_: any, record: any) => (
        <Space>
          <a onClick={() => handleOnLogin(record.id, record.users[0].id)}>{t('messages.login')}</a>
          <Popconfirm
            title={t('messages.confirmDelete')}
            description={t('messages.confirmDeleteDescription')}
            onConfirm={() => handleOnDelete(record.id)}
          >
            <a>{t('messages.delete')}</a>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const handlePageChange = (pageNumber: number) => {
    getAccounts({
      page: pageNumber,
      limit: 10,
      match: nameSearching ? { name: { $regex: escapeRegExp(nameSearching), $options: 'i' } } : undefined,
      userMatch: emailSearching ? { email: { $regex: escapeRegExp(emailSearching), $options: 'i' } } : undefined,
    })
  }

  const handleOnLogin = (accountId: string, userId: string) => {
    LocalStorage.setItem(ADMIN_USER_ID, userId)
    LocalStorage.setItem(ADMIN_ACCOUNT_ID, accountId)
    window.location.reload()
  }

  const handleOnDelete = (accountId: string) => {
    deleteAccount(accountId)
  }

  return (
    <>
      <InviteCompanyModal
        isAddCompanyModalOpen={isAddCompanyModalOpen}
        setIsAddCompanyModalOpen={setIsAddCompanyModalOpen}
        isLoading={createAccountMutationResult.isLoading}
        createAccountSubmit={createAccountSubmit}
      />
      <div className="page-header">
        <AiHelperHeader title={t('title')} />
        <div className="header-right-content">
          <Button id="invite-to-team-button" type="primary" onClick={() => setIsAddCompanyModalOpen(true)}>
            <UserAddOutlined />
            {t('addButton')}
          </Button>
        </div>
      </div>
      <div id="container">
        <Table
          className="admin-table"
          dataSource={dataSource?.accounts}
          columns={columns}
          rowKey={'id'}
          pagination={{
            total: dataSource?.total,
            onChange: handlePageChange,
            position: ['bottomCenter'],
          }}
          scroll={{ y: 400, x: 800 }}
        />
      </div>
    </>
  )
}

export default Accounts
