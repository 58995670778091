import { createApi } from '@reduxjs/toolkit/query/react'
import { LocalStorage } from 'ts-localstorage'
import { ITockens } from '../../types/auth.type'
import { ACCESS_TOKEN } from '../../config/consts'
import baseQueryWithReauth from '../baseQueryWithReauth'
import { IUser, EditUserRequest } from '../../types/users.type'

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['GetUsers', 'GetUsersNameEmailList'],
  endpoints: (builder) => ({
    getUsers: builder.query<IUser[], void>({
      query: () => ({
        url: `users`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
      providesTags: [{ type: 'GetUsers' }],
    }),
    getUsersNameEmailList: builder.query<IUser[], void>({
      query: () => ({
        url: `users/members`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
      providesTags: [{ type: 'GetUsersNameEmailList' }],
    }),
    createUser: builder.mutation<IUser, { email: string }>({
      query: (args) => ({
        url: `users`,
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: args,
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),
    updateUserById: builder.mutation<IUser, { body: EditUserRequest; id: string }>({
      query: ({ body, id }) => ({
        url: `users/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteUserById: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(usersApi.util.invalidateTags([{ type: 'GetUsers' }]))
      },
    }),
    inviteCreateSession: builder.mutation<ITockens, { email: string; code: string }>({
      query: (body) => ({
        url: `auth/verify`,
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body,
      }),
    }),
  }),
})

export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserByIdMutation,
  useDeleteUserByIdMutation,
  useInviteCreateSessionMutation,
  useGetUsersNameEmailListQuery,
} = usersApi
