import { Button, Form, Modal, notification } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useState } from 'react'
import SelectProject from '../../routes/project/components/SelectProject'
import { useGetProjectQuery } from '../../store/api/project.api'
import ZoomableImage from '../../routes/project/components/ZoomableImage'
import { useCreateObservationMutation } from '../../store/api/observation.api'
import { Message } from '../../types/storage.type'
import { ConversationContentItem } from '../../types/conversation.type'
import { useNavigate } from 'react-router-dom'

interface IPostObservation {
  conversationId: string | undefined
  messages: Array<Message> | undefined
}

interface FormValues {
  name: string
  projectId: string
  coordinates: {
    x: number
    y: number
  }
  photoList: any
}

export default function PostObservation({ messages, conversationId }: IPostObservation) {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [form] = Form.useForm()
  const [selectedProjectId, setSelectedProjectId] = useState<string>('')

  const navigation = useNavigate()

  const { data: singleProject } = useGetProjectQuery(selectedProjectId, {
    skip: !selectedProjectId,
  })

  const [createObservation, { isLoading: isCreating }] = useCreateObservationMutation()

  const onSubmit = async (values: FormValues) => {
    if (!messages) {
      return
    }
    if (Array.isArray(messages[0].content)) {
      try {
        const messageText = ((messages[1].content && messages[1]?.content) as string) || ''
        const images =
          messages[0].content.filter((el: ConversationContentItem) => el.type === 'image_url')[0].image_url?.url || ''

        await createObservation({
          ...values,
          x: values.coordinates.x,
          y: values.coordinates.y,
          photoList: [images],
          text: messageText,
          conversationId: conversationId as string,
        }).unwrap()

        form.resetFields()
        setIsOpenModal(false)
        notification.success({ message: 'Observation created successfully!' })
        navigation(`/projects/${selectedProjectId}`)
      } catch (error) {
        console.error('Failed to create Observation:', error)
        notification.error({ message: 'Failed to create Observation. Please try again.' })
      }
    }
  }

  return (
    <div>
      <Button
        size="large"
        type="primary"
        style={{ width: '100%', backgroundColor: '#022140' }}
        onClick={() => setIsOpenModal(true)}
        loading={isCreating}
      >
        Post Observation
      </Button>

      <Modal
        title={'Create new observation'}
        open={isOpenModal}
        onOk={() => form.submit()}
        okText={'Create'}
        onCancel={() => setIsOpenModal(false)}
      >
        <Form className="chat-form" form={form} layout={'vertical'} onFinish={onSubmit} disabled={false}>
          <Form.Item name="name" rules={[{ required: true, message: 'Name are required!' }]}>
            <TextArea name="name" autoFocus={true} size="large" autoSize={{ minRows: 1, maxRows: 1 }} />
          </Form.Item>
          <Form.Item
            name="projectId"
            style={{ margin: '0' }}
            rules={[{ required: true, message: 'Project are required!' }]}
          >
            <SelectProject
              returnProjectId={(e) => {
                form.setFieldValue('projectId', e)
                form.validateFields(['projectId'])
                setSelectedProjectId(e)
              }}
            />
          </Form.Item>
          {singleProject && (
            <Form.Item name={'coordinates'} rules={[{ required: true, message: 'Tap on location of observation' }]}>
              <ZoomableImage
                imageUrl={singleProject.mainPhoto || 'undefined'}
                projectId={singleProject.id}
                returnSetLocations={(e) => {
                  form.setFieldValue('coordinates', e)
                  form.validateFields(['coordinates'])
                }}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  )
}
