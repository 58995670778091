import React from 'react'
import { Button, Dropdown, MenuProps } from 'antd'
import './languageSelect.less'

interface ILabelButtonProps {
  title: string,
}

interface ILanguageSelectProps {
  initialLang: string | undefined
  setLanguage: (lang: string) => void
}

const LanguageSelect = ({ initialLang, setLanguage }: ILanguageSelectProps) => {

  const LabelButton = ({ title }: ILabelButtonProps) => (
    <Button className="dropdown-label-btn" type="text" onClick={() => setLanguage(title)}>
      {title}
    </Button>
  )


  const items: MenuProps['items'] = [
    { label: <LabelButton title="English" />, key: 'english' },
    { label: <LabelButton title="Albanian" />, key: 'albanian' },
    { label: <LabelButton title="Arabic" />, key: 'arabic' },
    { label: <LabelButton title="Armenian" />, key: 'armenian' },
    { label: <LabelButton title="Awadhi" />, key: 'awadhi' },
    { label: <LabelButton title="Azerbaijani" />, key: 'azerbaijani' },
    { label: <LabelButton title="Bashkir" />, key: 'bashkir' },
    { label: <LabelButton title="Basque" />, key: 'basque' },
    { label: <LabelButton title="Belarusian" />, key: 'belarusian' },
    { label: <LabelButton title="Bengali" />, key: 'bengali' },
    { label: <LabelButton title="Bhojpuri" />, key: 'bhojpuri' },
    { label: <LabelButton title="Bosnian" />, key: 'bosnian' },
    { label: <LabelButton title="Brazilian" />, key: 'brazilian' },
    { label: <LabelButton title="Bulgarian" />, key: 'bulgarian' },
    { label: <LabelButton title="Cantonese (Yue)" />, key: 'cantonese' },
    { label: <LabelButton title="Catalan" />, key: 'catalan' },
    { label: <LabelButton title="Chhattisgarhi" />, key: 'chhattisgarhi' },
    { label: <LabelButton title="Chinese" />, key: 'chinese' },
    { label: <LabelButton title="Croatian" />, key: 'croatian' },
    { label: <LabelButton title="Czech" />, key: 'czech' },
    { label: <LabelButton title="Danish" />, key: 'danish' },
    { label: <LabelButton title="Dogri" />, key: 'dogri' },
    { label: <LabelButton title="Dutch" />, key: 'dutch' },
    { label: <LabelButton title="Estonian" />, key: 'estonian' },
    { label: <LabelButton title="Faroese" />, key: 'faroese' },
    { label: <LabelButton title="Finnish" />, key: 'finnish' },
    { label: <LabelButton title="French" />, key: 'french' },
    { label: <LabelButton title="Galician" />, key: 'galician' },
    { label: <LabelButton title="Georgian" />, key: 'georgian' },
    { label: <LabelButton title="German" />, key: 'german' },
    { label: <LabelButton title="Greek" />, key: 'greek' },
    { label: <LabelButton title="Gujarati" />, key: 'gujarati' },
    { label: <LabelButton title="Haryanvi" />, key: 'haryanvi' },
    { label: <LabelButton title="Hindi" />, key: 'hindi' },
    { label: <LabelButton title="Hungarian" />, key: 'hungarian' },
    { label: <LabelButton title="Indonesian" />, key: 'indonesian' },
    { label: <LabelButton title="Irish" />, key: 'irish' },
    { label: <LabelButton title="Italian" />, key: 'italian' },
    { label: <LabelButton title="Japanese" />, key: 'japanese' },
    { label: <LabelButton title="Javanese" />, key: 'javanese' },
    { label: <LabelButton title="Kannada" />, key: 'kannada' },
    { label: <LabelButton title="Kashmiri" />, key: 'kashmiri' },
    { label: <LabelButton title="Kazakh" />, key: 'kazakh' },
    { label: <LabelButton title="Konkani" />, key: 'konkani' },
    { label: <LabelButton title="Korean" />, key: 'korean' },
    { label: <LabelButton title="Kyrgyz" />, key: 'kyrgyz' },
    { label: <LabelButton title="Latvian" />, key: 'latvian' },
    { label: <LabelButton title="Lithuanian" />, key: 'lithuanian' },
    { label: <LabelButton title="Macedonian" />, key: 'macedonian' },
    { label: <LabelButton title="Maithili" />, key: 'maithili' },
    { label: <LabelButton title="Malay" />, key: 'malay' },
    { label: <LabelButton title="Maltese" />, key: 'maltese' },
    { label: <LabelButton title="Mandarin" />, key: 'mandarin' },
    { label: <LabelButton title="Mandarin Chinese" />, key: 'mandarin chinese' },
    { label: <LabelButton title="Marathi" />, key: 'marathi' },
    { label: <LabelButton title="Marwari" />, key: 'marwari' },
    { label: <LabelButton title="Min Nan" />, key: 'min nan' },
    { label: <LabelButton title="Moldovan" />, key: 'moldovan' },
    { label: <LabelButton title="Mongolian" />, key: 'mongolian' },
    { label: <LabelButton title="Montenegrin" />, key: 'montenegrin' },
    { label: <LabelButton title="Nepali" />, key: 'nepali' },
    { label: <LabelButton title="Norwegian" />, key: 'norwegian' },
    { label: <LabelButton title="Oriya" />, key: 'oriya' },
    { label: <LabelButton title="Pashto" />, key: 'pashto' },
    { label: <LabelButton title="Persian (Farsi)" />, key: 'persian' },
    { label: <LabelButton title="Polish" />, key: 'polish' },
    { label: <LabelButton title="Portuguese" />, key: 'portuguese' },
    { label: <LabelButton title="Punjabi" />, key: 'punjabi' },
    { label: <LabelButton title="Rajasthani" />, key: 'rajasthani' },
    { label: <LabelButton title="Romanian" />, key: 'romanian' },
    { label: <LabelButton title="Russian" />, key: 'russian' },
    { label: <LabelButton title="Sanskrit" />, key: 'sanskrit' },
    { label: <LabelButton title="Santali" />, key: 'santali' },
    { label: <LabelButton title="Serbian" />, key: 'serbian' },
    { label: <LabelButton title="Sindhi" />, key: 'sindhi' },
    { label: <LabelButton title="Sinhala" />, key: 'sinhala' },
    { label: <LabelButton title="Slovak" />, key: 'slovak' },
    { label: <LabelButton title="Slovene" />, key: 'slovene' },
    { label: <LabelButton title="Slovenian" />, key: 'slovenian' },
    { label: <LabelButton title="Spanish" />, key: 'spanish' },
    { label: <LabelButton title="Swahili" />, key: 'swahili' },
    { label: <LabelButton title="Swedish" />, key: 'swedish' },
    { label: <LabelButton title="Tajik" />, key: 'tajik' },
    { label: <LabelButton title="Tamil" />, key: 'tamil' },
    { label: <LabelButton title="Tatar" />, key: 'tatar' },
    { label: <LabelButton title="Telugu" />, key: 'telugu' },
    { label: <LabelButton title="Thai" />, key: 'thai' },
    { label: <LabelButton title="Turkish" />, key: 'turkish' },
    { label: <LabelButton title="Turkmen" />, key: 'turkmen' },
    { label: <LabelButton title="Ukrainian" />, key: 'ukrainian' },
    { label: <LabelButton title="Urdu" />, key: 'urdu' },
    { label: <LabelButton title="Uzbek" />, key: 'uzbek' },
    { label: <LabelButton title="Vietnamese" />, key: 'vietnamese' },
    { label: <LabelButton title="Welsh" />, key: 'welsh' },
    { label: <LabelButton title="Wu" />, key: 'wu' },
  ]

  return (
    <Dropdown menu={{ items }} trigger={['click']} overlayClassName="language-settings-dropdown-overlay">
      <Button className="lng-container-btn">{initialLang || 'English'}</Button>
    </Dropdown>
  )
}

export default LanguageSelect
