import React from 'react'
import ObservationStatusCard from './ObservationStatusCard'
import { useAppSelector } from '../../../hooks/appHook'

export default function ObservationCards() {
  const { observations } = useAppSelector((state) => state.observations)

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <ObservationStatusCard status="addressed" count={observations.addressedCount} />
      <ObservationStatusCard status="inProgress" count={observations.inProgressCount} />
      <ObservationStatusCard status="notAddressed" count={observations.notAddressedCount} />
    </div>
  )
}
