import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Observation, ObservationsResponse } from '../../types/observation.type'
import { observationApi } from '../api/observation.api'

export const initialState: { observations: ObservationsResponse; selectedObservation: Observation } = {
  observations: {
    observations: [],
    count: 0,
    addressedCount: 0,
    inProgressCount: 0,
    notAddressedCount: 0,
  },
  selectedObservation: {
    id: '',
    name: '',
    projectId: '',
    photoList: [''],
    x: 0,
    y: 0,
    status: 'Addressed',
    implementedActions: '',
    hazardIdentified: '',
    isPublished: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    text: '',
  },
}

export const observationsSlice = createSlice({
  name: 'observations',
  initialState,
  reducers: {
    setObservations: (state, action: PayloadAction<ObservationsResponse>) => {
      state.observations = action.payload
    },
    setSelectedObservation: (state, action: PayloadAction<Observation>) => {
      state.selectedObservation = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(observationApi.endpoints?.getFilterObservations.matchFulfilled, (state, action) => {
      state.observations = action.payload
    })
  },
})

export const { setObservations, setSelectedObservation } = observationsSlice.actions

export default observationsSlice.reducer
