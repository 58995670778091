import React from 'react'
import { Popconfirm, Row, Select, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useAppSelector } from '../../../hooks/appHook'
import { useGetUsersQuery, useUpdateUserByIdMutation } from '../../../store/api/users.api'
import { useDeleteProfileMutation } from '../../../store/api/profile.api'
import { AccountRolesEnum, RolesEnum } from '../../../config/rolesEnum'
import { useSuccessMessage } from '../../../hooks/useSuccessMessage'
import AiHelperHeader from '../../../components/AIHelperHeader/aiHelperHeader'
import TeamInviteControl from './TeamInviteControl/TeamInviteControl'
import { QuestionCircleOutlined } from '@ant-design/icons'
import '../accounts.less'
import { formatDate } from '../../../helpers/date'
import { useTranslation } from 'react-i18next'

const { Option } = Select

interface DataType {
  name: string
  email: string
  id: string
}

const Team = () => {
  const { user } = useAppSelector((state) => state.appState)
  const users = useAppSelector((state) => state.users.users)
  const { t } = useTranslation('accounts')

  const { isLoading } = useGetUsersQuery(undefined, { refetchOnMountOrArgChange: true })

  const [userDelete, userDeleteMutationResult] = useDeleteProfileMutation()
  const [userUpdate, userUpdateMutationResult] = useUpdateUserByIdMutation()

  const USER_ROLE_DESCRIPTIONS = {
    user: t('team.userRoleDescriptions.user'),
    admin: t('team.userRoleDescriptions.admin'),
  }

  const ACCOUNT_ROLE_DESCRIPTIONS = {
    user: t('team.accountRoleDescriptions.user'),
    admin: t('team.accountRoleDescriptions.admin'),
  }

  const columns: ColumnsType<DataType> = [
    {
      title: t('team.columns.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('team.columns.email'),
      dataIndex: 'email',
      key: 'email',
    },
    ...(user.role === RolesEnum.ADMIN
      ? [
          {
            title: t('team.columns.globalRole'),
            dataIndex: 'role',
            key: 'role',
            render: (value: RolesEnum, record: any) => (
              <Select
                defaultValue={value}
                onChange={(v: RolesEnum) => userUpdate({ body: { role: v }, id: record.id })}
                style={{ width: 115 }}
                optionLabelProp="children"
              >
                {Object.entries(RolesEnum).map(([label, value]) => (
                  <Option value={value} key={value}>
                    <Row justify="space-between">
                      {label}
                      <Tooltip placement="topLeft" title={USER_ROLE_DESCRIPTIONS[value]}>
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Row>
                  </Option>
                ))}
              </Select>
            ),
          },
        ]
      : []),
    {
      title: 'Account role',
      dataIndex: 'accountRole',
      key: 'accountRole',
      render: (value: AccountRolesEnum, record: any) =>
        user.accountRole === AccountRolesEnum.ADMIN && user.id !== record.id ? (
          <Select
            defaultValue={value}
            onChange={(v: AccountRolesEnum) => userUpdate({ body: { accountRole: v }, id: record.id })}
            style={{ width: 115 }}
            optionLabelProp="children"
          >
            {Object.entries(AccountRolesEnum).map(([label, value]) => (
              <Option value={value} key={value}>
                <Row justify="space-between">
                  {label}
                  <Tooltip placement="topLeft" title={ACCOUNT_ROLE_DESCRIPTIONS[value]}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Row>
              </Option>
            ))}
          </Select>
        ) : (
          value.toUpperCase()
        ),
    },

    {
      title: t('team.columns.lastLogin'),
      dataIndex: 'lastLoginAt',
      key: 'lastLogin',
      render: (value) => (value ? formatDate(value) : 'Pending acceptance'),
    },
    {
      title: t('team.columns.actions'),
      dataIndex: '',
      render: (_: any, record: { id: string }) =>
        user.accountRole === AccountRolesEnum.ADMIN &&
        record.id !== user.id && (
          <Popconfirm
            title={t('team.deleteConfirmationTitle')}
            description={t('team.deleteConfirmationDescription')}
            onConfirm={() => handleDeleteUser(record.id)}
          >
            <a>{t('team.columns.deleteAction')}</a>
          </Popconfirm>
        ),
    },
  ]

  const handleDeleteUser = (userId: string) => {
    userDelete(userId)
  }

  useSuccessMessage(t('team.successMessages.userDeleted'), userDeleteMutationResult.isSuccess)
  useSuccessMessage(t('team.successMessages.userUpdated'), userUpdateMutationResult.isSuccess)

  return (
    <>
      <AiHelperHeader title={t('team.headerTitle')} paragraph={t('team.headerParagraph')} />
      <TeamInviteControl />
      <div id="container">
        <Table
          className="admin-table"
          columns={columns}
          rowKey={'id'}
          loading={isLoading}
          pagination={{ position: ['bottomCenter'] }}
          dataSource={users}
          scroll={{ y: 400, x: 800 }}
        />
      </div>
    </>
  )
}

export default Team
