import React from 'react'
import { Button, Form, Input, Modal, notification } from 'antd'
import { useAppSelector } from '../../../../../hooks/appHook'
import { usePutUpdateObservationsMutation } from '../../../../../store/api/observation.api'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
}

const RenameObservationModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [form] = Form.useForm()
  const { selectedObservation } = useAppSelector((state) => state.observations)

  const [updateObservation, { isLoading: isUpdating }] = usePutUpdateObservationsMutation()

  const isLoading = isUpdating

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      await updateObservation({ observationId: selectedObservation.id, data: values }).unwrap()
      form.resetFields()
      onClose()
      notification.success({ message: 'Observation name changed successfully!' })
    } catch (error) {
      console.error('Failed to change Observation name:', error)
      notification.error({ message: 'Failed to change Observation name. Please try again.' })
    }
  }

  return (
    <Modal
      title="Rename observation"
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmit}>
          Change
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Observation name*" name="name">
          <Input placeholder="Project name" defaultValue={selectedObservation.name} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default RenameObservationModal
