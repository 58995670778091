import React from 'react'
import { Button, Row } from 'antd'
import { IStatus } from '../../../types/observation.type'
import { useNavigate } from 'react-router-dom'

export const statusTitle = {
  addressed: 'Addressed',
  inProgress: 'In progress',
  notAddressed: 'Not addressed',
}

export default function ObservationStatusCard({ status, count }: { status: IStatus; count: number }) {
  const navigate = useNavigate()
  const datBackgroundColor = status === 'addressed' ? '#2C875D' : status === 'inProgress' ? '#FFBF00' : '#FF0D31'
  return (
    <div
      style={{
        border: '1px solid #EBEBEB',
        borderRadius: 8,
        padding: '10px 20px 0px',
      }}
    >
      <Row style={{ alignItems: 'center', gap: 10 }}>
        <div style={{ borderRadius: 50, backgroundColor: datBackgroundColor, width: 16, height: 16 }} />
        <div>
          <span style={{ fontSize: 24, fontWeight: 500 }}>{count}</span>
        </div>
      </Row>
      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <p style={{ fontSize: 16, fontWeight: 500 }}>{statusTitle[status]}</p>
        <Button onClick={() => navigate(`observations/${status}`)}>View details</Button>
      </Row>
    </div>
  )
}
