import React, { useState } from 'react'
import { Button, Form, Input, Modal, notification, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useUpdateProjectMutation } from '../../../../store/api/project.api'
import { useUploadFileMutation } from '../../../../store/api/uploads.api'
import { useAppSelector } from '../../../../hooks/appHook'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
}

const EditProjectModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [form] = Form.useForm()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [fileList, setFileList] = useState<any[]>([])
  const { project } = useAppSelector((state) => state.project)

  const [updateProject, { isLoading: isUpdating }] = useUpdateProjectMutation()
  const [uploadFile, { isLoading: isUploading }] = useUploadFileMutation()

  const isLoading = isUpdating || isUploading

  const handleFileChange = (info: any) => {
    const newFileList = info.fileList.slice(-1)
    setFileList(newFileList)
    setSelectedFile(newFileList.length > 0 ? newFileList[0].originFileObj : null)
  }

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()

      let uploadedUrl
      if (selectedFile) {
        const formData = new FormData()
        selectedFile && formData.append('file', selectedFile)
        const uploadResponse = await uploadFile({ body: formData }).unwrap()
        uploadedUrl = uploadResponse.url
      }

      const projectPayload = {
        ...values,
        mainPhoto: uploadedUrl || project.mainPhoto,
      }

      await updateProject({ projectId: project.id, data: projectPayload }).unwrap()

      form.resetFields()
      setSelectedFile(null)
      setFileList([])
      onClose()
      notification.success({ message: 'Project edited successfully!' })
    } catch (error) {
      console.error('Failed to create project:', error)
      notification.error({ message: 'Failed to edit project. Please try again.' })
    }
  }

  return (
    <Modal
      title="Edit project"
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmit}>
          Edit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Name" name="name">
          <Input placeholder="Project name" defaultValue={project.name} />
        </Form.Item>
        <Form.Item label="Project Number" name="projectNumber">
          <Input placeholder="Project Number" defaultValue={project.projectNumber} />
        </Form.Item>
        <Form.Item label="Main Photo">
          <Upload
            fileList={fileList}
            beforeUpload={() => false} // Prevent automatic upload
            onChange={handleFileChange}
            onRemove={() => {
              setFileList([])
              setSelectedFile(null)
            }}
            accept="image/*"
          >
            <Button icon={<UploadOutlined />}>Select Image</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditProjectModal
