import React from 'react'
import { Button, Form, Input, Modal, notification, Select } from 'antd'
import { useAppSelector } from '../../../../../hooks/appHook'
import { usePutUpdateObservationsMutation } from '../../../../../store/api/observation.api'
import TextArea from 'antd/es/input/TextArea'

const { Option } = Select

interface ModalProps {
  isOpen: boolean
  onClose: () => void
}

const ChangeStatusObservationModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [form] = Form.useForm()
  const { selectedObservation } = useAppSelector((state) => state.observations)
  const [updateObservation, { isLoading: isUpdating }] = usePutUpdateObservationsMutation()

  const isLoading = isUpdating

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      await updateObservation({ observationId: selectedObservation.id, data: values }).unwrap()
      form.resetFields()
      onClose()
      notification.success({ message: 'Observation status changed successfully!' })
    } catch (error) {
      console.error('Failed to change Observation status:', error)
      notification.error({ message: 'Failed to change Observation status. Please try again.' })
    }
  }

  return (
    <Modal
      title="Change Status"
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmit}>
          Change
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Status" name="status">
          <Select placeholder="Select a status" optionFilterProp="children">
            <Option value="Addressed">Addressed</Option>
            <Option value="In progress">In progress</Option>
            <Option value="Not addressed">Not addressed</Option>
          </Select>
        </Form.Item>

        <Form.Item shouldUpdate>
          {({ getFieldValue }) =>
            getFieldValue('status') === 'Addressed' ? (
              <Form.Item
                label="Implemented actions"
                name="implementedActions"
                rules={[{ required: true, message: 'Implemented actions are required!' }]}
              >
                <TextArea rows={4} />
              </Form.Item>
            ) : null
          }
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ChangeStatusObservationModal
