import React, { useCallback, useEffect, useRef, useState } from 'react'
import './zoomableImage.less'
import RenderedPins from './RenderedPins'
import { IPin } from '../../../types/observations'
import { useGetFilterObservationsQuery } from '../../../store/api/observation.api'

interface IZoomableImage {
  imageUrl: string
  projectId: string
  returnSetLocations?: (e: IPin) => void
  selectedObservation?: string
}

const ZoomableImage = ({ imageUrl, projectId, returnSetLocations, selectedObservation }: IZoomableImage) => {
  const [isZoomed, setIsZoomed] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 })

  const [pin, setPin] = useState<IPin | null>(null)

  const containerRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  const { data: observations } = useGetFilterObservationsQuery({
    projectId: projectId || '',
  })

  const handlePinClick = (e: React.MouseEvent<HTMLImageElement>) => {
    if (!containerRef.current || !imageRef.current || !returnSetLocations) {
      return
    }
    const rect = containerRef.current.getBoundingClientRect()
    const img = imageRef.current

    const { naturalWidth, naturalHeight } = img

    const x = (e.clientX - rect.left) / rect.width
    const y = (e.clientY - rect.top) / rect.height

    const pinX = (x * naturalWidth) / naturalWidth
    const pinY = (y * naturalHeight) / naturalHeight

    setPin({ x: pinX, y: pinY, id: y + x, type: 'observation' })
    returnSetLocations({ x: pinX, y: pinY, id: y + x, type: 'observation' })
  }

  const handleDoubleClick = (e: React.MouseEvent<HTMLImageElement>) => {
    if (!containerRef.current) {
      return
    }
    const rect = containerRef.current.getBoundingClientRect()

    const x = ((e.clientX - rect.left) / rect.width) * 100
    const y = ((e.clientY - rect.top) / rect.height) * 100
    if (isZoomed) {
      setIsZoomed(false)
    } else {
      setPosition({ x, y })
      setIsZoomed(true)
    }
  }

  const showPins = useCallback((): IPin[] | [] => {
    if (observations?.observations?.length) {
      const selected = selectedObservation
        ? observations?.observations.filter((obs) => obs.id === selectedObservation)
        : observations?.observations
      return selected.map((obs) => ({
        id: obs.x + obs.y,
        x: obs.x,
        y: obs.y,
        status: obs.status,
      }))
    }
    return []
  }, [observations?.observations])

  useEffect(() => {
    const img = imageRef.current
    if (!img) {
      return
    }
    const updateImageSize = () => {
      setImageSize({
        width: img.width,
        height: img.height,
      })
    }
    if (img.complete) {
      updateImageSize()
    } else {
      img.onload = updateImageSize
    }
    return () => {
      if (img) {
        img.onload = null
      }
    }
  }, [imageUrl])

  return (
    <div className="zoomImage-container">
      <div
        ref={containerRef}
        style={{
          transition: 'transform 0.3s ease-in-out',
          cursor: returnSetLocations ? 'crosshair' : isZoomed ? 'zoom-out' : 'zoom-in',
          transform: isZoomed ? 'scale(5)' : 'scale(1)',
          transformOrigin: isZoomed ? `${position.x}% ${position.y}%` : 'center',
        }}
      >
        <img
          ref={imageRef}
          className="zoomImage"
          src={imageUrl}
          alt={imageUrl}
          onClick={handlePinClick}
          onDoubleClick={handleDoubleClick}
        />
        <RenderedPins pin={pin} pinData={showPins()} imageSize={imageSize} currentScale={isZoomed ? 5 : 1} />
      </div>
    </div>
  )
}

export default ZoomableImage
