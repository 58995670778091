import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '../../types/appState.type'
import { IUser } from '../../types/profile.type'
import { AccountRolesEnum, RolesEnum } from '../../config/rolesEnum'
import { LocalStorage } from 'ts-localstorage'
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../config/consts'
import jwtDecode from 'jwt-decode'
import { authApi } from '../api/auth.api'
import { usersApi } from '../api/users.api'
import { accountApi } from '../api/account.api'

const accessToken = <string>LocalStorage.getItem(ACCESS_TOKEN)
const initUser = accessToken ? jwtDecode<IUser>(accessToken) : { id: '', role: RolesEnum.USER, accountId: '' }

export const initialState: AppState = {
  modals: {
    isInviteToTeamModalOpen: false,
    isCreateStorageModalOpen: false,
    isAddImageByUrlModalOpen: false,
    isCreateAIAgentModalOpen: false,
    isOnBoardingInfoModalOpen: false,
    isIntegrationEmailModalOpen: false,
    isIntegrationTooltipOpen: true,
  },
  user: {
    id: '',
    role: initUser.role,
    accountId: '',
    name: '',
    email: '',
    picture: '',
    status: '',
    accountRole: AccountRolesEnum.USER,
    language: '',
  },
}

export const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.user.role = jwtDecode<IUser>(action.payload.accessToken)?.role
      LocalStorage.setItem(ACCESS_TOKEN, action.payload.accessToken)
      LocalStorage.setItem(REFRESH_TOKEN, action.payload.refreshToken)
    },
    setAppLanguage: (state, action) => {
      state.user.language = action.payload
    },
    userResetState: () => initialState,
    setIsInviteTeamModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.isInviteToTeamModalOpen = action.payload
    },
    setAddImageByUrlModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.isAddImageByUrlModalOpen = action.payload
    },
    setIsCreateStorageModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.isCreateStorageModalOpen = action.payload
    },
    setIsCreateAIAgentModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.isCreateAIAgentModalOpen = action.payload
    },
    setIsOnboardingInfoModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.isOnBoardingInfoModalOpen = action.payload
    },
    setIsIntegrationEmailModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.isIntegrationEmailModalOpen = action.payload
    },
    setIsIntegrationTooltipOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.isIntegrationTooltipOpen = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.signIn.matchFulfilled, (state, action) => {
        state.user = jwtDecode<IUser>(action.payload.accessToken)
        LocalStorage.setItem(ACCESS_TOKEN, action.payload.accessToken)
        LocalStorage.setItem(REFRESH_TOKEN, action.payload.refreshToken)
      })
      .addMatcher(authApi.endpoints.signUp.matchFulfilled, (state, action) => {
        state.user = jwtDecode<IUser>(action.payload.accessToken)
        LocalStorage.setItem(ACCESS_TOKEN, action.payload.accessToken)
        LocalStorage.setItem(REFRESH_TOKEN, action.payload.refreshToken)
      })
      .addMatcher(usersApi.endpoints.inviteCreateSession.matchFulfilled, (state, action) => {
        state.user = jwtDecode<IUser>(action.payload.accessToken)
        LocalStorage.setItem(ACCESS_TOKEN, action.payload.accessToken)
        LocalStorage.setItem(REFRESH_TOKEN, action.payload.refreshToken)
      })
      .addMatcher(accountApi.endpoints.getAccount.matchFulfilled, (state, action: any) => {
        state.user = { ...state.user, ...action.payload.user, accountId: action.payload.account.id }
      })
  },
})

export const { setAccessToken, userResetState, setAppLanguage } = appStateSlice.actions
export const appStateActionCreators = appStateSlice.actions

export default appStateSlice.reducer
