import React, { ChangeEvent, KeyboardEventHandler, useCallback, useState } from 'react'
import { Button, Form, Input, notification } from 'antd'
import './chatMessageInput.less'
import { SyncOutlined } from '@ant-design/icons'
import AddImagesPopover from '../AddImagesPopover/AddImagesPopover'
import { FileRejection, useDropzone } from 'react-dropzone'
import SelectedImages from '../SelectedImages/SelectedImages'
import AddImageByLinkModal from '../../UI/Modals/AddImageByLinkModal'
import OpenaiRealtimeButton from '../OpenAiRealtime/OpenaiRealtimeButton'
import { Conversation, ConversationMessageItem } from '../../../types/conversation.type'
import { useTranslation } from 'react-i18next'
import Icons from '../../UI/Icons/Icons'
import { useAppConfig } from '../../../hooks/appHook'
import PostObservation from '../../PostObservation/PostObservation'
import { Message } from '../../../types/storage.type'

const { TextArea } = Input

type IProps = {
  onSendChatMessage: (values: ConversationMessageItem) => Promise<boolean>
  isLoading: boolean
  isRealtimeAllowed: boolean | undefined
  conversationId: string
  refetchMessages: () => void
  createConversation: () => Promise<Conversation | undefined>
  messages: Array<Message> | undefined
}

const ChatMessageInput = ({
  onSendChatMessage,
  isLoading,
  isRealtimeAllowed,
  refetchMessages,
  conversationId,
  createConversation,
  messages,
}: IProps) => {
  const { appFeatures } = useAppConfig()

  const [newMessage, setNewMessage] = useState('')
  const [uploadedImages, setUploadedImages] = useState<Array<File | string>>([])
  const [isRealtimeVisible, setIsRealtimeVisible] = useState<boolean>(false)
  const [form] = Form.useForm()

  const { t } = useTranslation('components')
  /** HANDLERS */
  const onSubmit = async (values: { newMessage: string }) => {
    const linksImages = uploadedImages.filter((image) => typeof image === 'string' && image) as string[]
    const fileImages = uploadedImages.filter((image) => typeof image !== 'string' && image) as File[]

    if (![...linksImages, ...fileImages].length && !values.newMessage?.trim()) {
      return
    }

    const request: ConversationMessageItem = {
      message: values.newMessage,
      linksImages,
      fileImages,
    }

    const isSent = await onSendChatMessage(request)
    if (isSent) {
      setUploadedImages([])
      form.resetFields()
      setNewMessage('')
    }
  }

  const onPressEnter: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      form.submit()
    }
  }

  const handleImageLinkAdd = useCallback((imageLink: string) => {
    setUploadedImages((prev) => [...prev, imageLink])
  }, [])

  const onRemoveImage = useCallback((index: number) => {
    setUploadedImages((prev) => prev.filter((_, i) => i !== index))
  }, [])

  const onInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNewMessage(e.target.value)
  }

  /** DROP ZONE */
  const onDrop = (files: File[]) => {
    const validFiles = files.filter(
      (file) =>
        ['image/png', 'image/jpeg', 'image/webp', 'image/gif'].includes(file.type) && file.size <= 20 * 1024 * 1024,
    )
    if (uploadedImages.length + validFiles.length <= 5) {
      setUploadedImages((prev) => [...prev, ...validFiles])
    } else {
      notification.error({
        message: 'Max count 5',
      })
    }
  }

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    notification.error({
      message: rejectedFiles[0].errors[0].message,
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/webp': [],
      'image/gif': [],
    },
    maxSize: 20 * 1024 * 1024,
    noClick: true,
  })

  return (
    <Form className="chat-form" form={form} layout={'vertical'} onFinish={onSubmit} disabled={false}>
      {messages && messages?.length > 1 ? (
        <PostObservation messages={messages} conversationId={conversationId} />
      ) : (
        <>
          <div {...getRootProps()}>
            {uploadedImages?.length > 0 ? <SelectedImages images={uploadedImages} removeImage={onRemoveImage} /> : null}
            <input {...getInputProps()} />
            <Form.Item name="newMessage" style={{ margin: '0' }}>
              <TextArea
                className={appFeatures?.addImagesToChat ? 'chat-form-input with-attachment' : 'chat-form-input'}
                placeholder={t('chat.chatMessageInput.placeholder')}
                name="newMessage"
                autoFocus={true}
                autoSize={{ minRows: 1, maxRows: 5 }}
                onPressEnter={onPressEnter}
                value={newMessage}
                onChange={onInputChange}
              />
            </Form.Item>
          </div>
          {appFeatures?.addImagesToChat && (
            <div className="upload-file-wrapper">
              <AddImagesPopover isFullSelectedImages={false} onDrop={onDrop} disabled={false} />
            </div>
          )}
          {isLoading ? (
            <SyncOutlined className="loader" spin={true} />
          ) : (
            <div className="action-icons-wrapper">
              <div className="realtime-wrapper">
                {isRealtimeAllowed && (
                  <OpenaiRealtimeButton
                    isRealtimeVisible={isRealtimeVisible && isRealtimeAllowed}
                    setIsRealtimeVisible={(v) => setIsRealtimeVisible(v)}
                    conversationId={conversationId}
                    refetchMessages={refetchMessages}
                    createConversation={createConversation}
                  />
                )}
              </div>

              <Button className="send-message-button" onClick={() => form.submit()}>
                <Icons name="send" className="send-message-icon" width={22} height={22} />
              </Button>
            </div>
          )}

          <AddImageByLinkModal handleImageLinkAdd={handleImageLinkAdd} />
        </>
      )}
    </Form>
  )
}

export default ChatMessageInput
