import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Table, Tag } from 'antd'
import AiHelperHeader from '../../components/AIHelperHeader/aiHelperHeader'
import './conversations.less'
import { useTranslation } from 'react-i18next'
import { useAppConfig } from '../../hooks/appHook'
import { formatDate } from '../../helpers/date'
import { DislikeOutlined, LikeOutlined } from '@ant-design/icons'
import { Conversation } from '../../types/conversation.type'
import { useGetFilterObservationsQuery } from '../../store/api/observation.api'
import { SortDirectionEnum } from '../../types/pagination-params.type'

const Conversations = () => {
  const { t } = useTranslation('conversations')
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const { appName } = useAppConfig()

  const { data: observations, isLoading } = useGetFilterObservationsQuery(
    { pagination: { page: currentPage, pageSize, sortBy: 'createdAt', sortDirection: SortDirectionEnum.DESC } },
    { refetchOnMountOrArgChange: true },
  )
  const conversationsFormObservations = observations?.observations?.map((observation) => ({
    ...observation.conversationId,
    name: observation.name,
  }))

  const columns = [
    {
      title: 'Observation',
      dataIndex: ['name'],
      key: 'name',
      render: (value: string, record: any) => {
        return (
          <Link to={`${record._id || record?.id}`} className="conversation-url">
            {record?.name || record?.id}
            {record.unread && record.unread === true && (
              <Tag color="green" style={{ height: 22 }}>
                {t('new')}
              </Tag>
            )}
          </Link>
        )
      },
    },
    {
      title: t('feedback'),
      dataIndex: ['analytics', 'messages'],
      key: 'analytics',
      render: (_: any, record: Conversation) => {
        const ratingFromMessage = record?.messages?.find((m: any) => m.feedback?.[0]?.rating)
        const rating = record?.analytics?.feedback?.[0]?.rating || ratingFromMessage?.feedback?.[0]?.rating
        if (rating !== 'like' && rating !== 'dislike') {
          return null
        }
        return (
          <p style={{ padding: '10px' }}>
            {rating &&
              (rating === 'like' ? (
                <LikeOutlined style={{ fontSize: '25px' }} />
              ) : (
                <DislikeOutlined style={{ fontSize: '25px' }} />
              ))}
          </p>
        )
      },
    },
    {
      title: t('satisfactory'),
      dataIndex: ['analytics', 'satisfactory'],
      key: 'satisfactory',
    },
    {
      title: t('infoFound'),
      dataIndex: ['analytics', 'infoFounded'],
      key: 'infoFounded',
    },
    {
      title: t('dateTime'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value: string, record: any) => {
        return (
          <>
            <p>{formatDate(value)}</p>
            {record.unread && record.unread === true && (
              <Tag color="green" style={{ height: 22 }}>
                {t('new')}
              </Tag>
            )}
          </>
        )
      },
    },
    {
      title: t('customerName'),
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: t('customerEmail'),
      dataIndex: 'customerEmail',
      key: 'customerEmail',
    },
  ]

  const handleTableChange = (page: number, size: number) => {
    setCurrentPage(page)
    setPageSize(size)
  }

  return (
    <>
      <AiHelperHeader title={'Observations'} paragraph={t('defaultParagraph', { name: appName })} />
      <div id="container">
        <Table
          id="table"
          dataSource={conversationsFormObservations}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          //@ts-ignore
          columns={columns}
          rowKey={'id'}
          loading={isLoading}
          pagination={{
            current: currentPage,
            pageSize,
            total: observations?.count,
            showSizeChanger: true,
            onChange: handleTableChange,
            onShowSizeChange: handleTableChange,
            position: ['bottomCenter'],
          }}
          scroll={{ y: 400, x: 800 }}
        />
      </div>
    </>
  )
}

export default Conversations
