export enum SortDirectionEnum {
  ASC = 'asc',
  DESC = 'desc',
}

export interface IPaginationParams {
  page: number
  pageSize: number
  sortBy?: string
  sortDirection?: SortDirectionEnum
  startPeriod?: Date | undefined; 
  finishPeriod?: Date | undefined
}
