import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGetProjectQuery } from '../../store/api/project.api'
import ZoomableImage from './components/ZoomableImage'
import ActionButtons from './components/ProjectActionButtons'
import { useDispatch } from 'react-redux'
import { setActiveProject } from '../../store/slices/project.slice'
import ObservationCards from './components/ObservationCards'
import { Col, Row } from 'antd'
import { useGetFilterObservationsQuery } from '../../store/api/observation.api'
import SelectProject from './components/SelectProject'
import { useAppSelector } from '../../hooks/appHook'
import { AccountRolesEnum, RolesEnum } from '../../config/rolesEnum'
import Loader from '../../components/UI/Loader/Loader'

export default function Project() {
  const { projectId } = useParams()
  const dispatch = useDispatch()
  const { user } = useAppSelector((state) => state.appState)
  const hasPermissions = user.role === RolesEnum.ADMIN || user.accountRole === AccountRolesEnum.ADMIN
  const { data: project, isLoading: projectIsLoading } = useGetProjectQuery(projectId as string, {
    skip: !projectId,
  })
  useGetFilterObservationsQuery(
    { projectId: project?.id || '' },
    {
      skip: !project?.id,
    },
  )

  useEffect(() => {
    if (project) {
      dispatch(setActiveProject(project))
    }
  }, [project])

  if (projectIsLoading) {
    return <Loader fullScreenHeight />
  }

  return (
    <div style={{ margin: 20 }}>
      <SelectProject status={project?.status} />
      <Row style={{ gap: 20 }}>
        <Col span={16}>
          {project && <ZoomableImage imageUrl={project?.mainPhoto} projectId={project?.id} />}
          {hasPermissions && <ActionButtons />}
        </Col>
        <Col span={7}>
          <ObservationCards />
        </Col>
      </Row>
    </div>
  )
}
