import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithReauth from '../baseQueryWithReauth'
import { ObservationsResponse, StatusTitle, ICreateObservation, Observation } from '../../types/observation.type'
import { IPaginationParams } from '../../types/pagination-params.type'

interface IUpdateObservation {
  data: {
    implementedActions?: string
    status?: StatusTitle
    name?: string
  }
}

export const observationApi = createApi({
  reducerPath: 'observationApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Observations'],
  endpoints: (builder) => ({
    createObservation: builder.mutation<ObservationsResponse, ICreateObservation>({
      query: (body) => ({
        url: `observations`,
        method: 'post',
        body,
      }),
      invalidatesTags: ['Observations'],
    }),
    getFilterObservations: builder.query<
      ObservationsResponse,
      { projectId?: string; status?: string; pagination?: IPaginationParams }
    >({
      query: ({ projectId, status, pagination }) => ({
        url: 'observations',
        method: 'GET',
        params: { projectId, status, ...pagination },
      }),
      providesTags: ['Observations'],
    }),
    getObservation: builder.query<Observation, string>({
      query: (id) => ({
        url: 'observations/' + id,
        method: 'GET',
      }),
    }),
    putUpdateObservations: builder.mutation<ObservationsResponse, { observationId: string; data?: IUpdateObservation }>(
      {
        query: ({ observationId, data }) => ({
          url: `observations/${observationId}`,
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: (result, error, { observationId }) => [{ type: 'Observations', projectId: observationId }],
      },
    ),
    deleteObservations: builder.mutation<ObservationsResponse, { observationId: string }>({
      query: ({ observationId }) => ({
        url: `observations/${observationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { observationId }) => [{ type: 'Observations', projectId: observationId }],
    }),
  }),
})

export const {
  useCreateObservationMutation,
  useGetFilterObservationsQuery,
  useGetObservationQuery,
  usePutUpdateObservationsMutation,
  useDeleteObservationsMutation,
} = observationApi
