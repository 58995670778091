import React, { useEffect } from 'react'
import ChatMessagesHistory from '../../../../components/Chat/ChatMessagesHistory/ChatMessagesHistory'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDeleteConversationMutation, useGetConversationByIdQuery } from '../../../../store/api/conversation.api'
import { Button, notification, Popconfirm, Tooltip } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import './conversation.less'
import ConversationTextFeedback from '../../../../components/Feedback/ConversationTextFeedback/ConversationTextFeedback'
import { useAppSelector } from '../../../../hooks/appHook'
import { useGetAgentListQuery } from '../../../../store/api/agent.api'
import ConversationTextFeedbackHistory from '../../../../components/Feedback/ConversationTextFeedbackHistory/ConversationTextFeedbackHistory'
import { RolesEnum } from '../../../../config/rolesEnum'

export const Conversation = () => {
  const { t } = useTranslation('conversations')

  const navigate = useNavigate()
  const { conversationId } = useParams()
  const { user } = useAppSelector((state) => state.appState)

  /** API */
  const { data, isFetching, refetch } = useGetConversationByIdQuery({ id: conversationId || '' })
  const [deleteConversation, deleteConversationResult] = useDeleteConversationMutation()
  useGetAgentListQuery()
  const isGlobalAdmin = user.role === RolesEnum.ADMIN
  const myFeedback = data?.analytics?.feedback?.find(
    (f) =>
      (typeof f.userId === 'string' ? f.userId : f.userId?.id) === user.id &&
      f.whatIsWrong &&
      f.whyWrong &&
      f.correctResponse,
  )
  /** HANDLERS */
  const onDelete = () => {
    deleteConversation(conversationId || '')
  }

  /** useEffect */
  useEffect(() => {
    if (deleteConversationResult.isSuccess) {
      navigate(-1)
      notification.success({ message: t('conversation.successDeleted') })
    }
  }, [deleteConversationResult.isSuccess])

  const showFeedbackHistory = !!myFeedback || isGlobalAdmin
  const filteredFeedback = data?.analytics?.feedback?.filter((f) => f.whatIsWrong && f.whyWrong && f.correctResponse)

  return (
    <div className="conversation">
      <div className="innerContainer">
        <div className="content">
          <div className="header">
            <h1 className="headerTitle">{data?.name || data?.id || t('headerTitle')}</h1>
            <div className="actions-buttons-wrapper">
              {/*<LikeDislikeConversationButtons*/}
              {/*  conversationId={conversationId || ''}*/}
              {/*  feedback={data?.analytics?.feedback}*/}
              {/*/>*/}

              <Popconfirm
                title={t('conversation.confirmDelete')}
                onConfirm={() => onDelete()}
                okText={t('yes')}
                cancelText={t('no')}
                placement={'leftTop'}
              >
                <Tooltip title={t('conversation.deleteConversation')}>
                  <Button icon={<DeleteOutlined />} />
                </Tooltip>
              </Popconfirm>
            </div>
          </div>

          {!myFeedback && (
            <div>
              <ConversationTextFeedback conversationId={conversationId || ''} />
            </div>
          )}
          {showFeedbackHistory && !!data?.analytics?.feedback?.length && (
            <>
              <h2 className="title">{t('conversation.title')}</h2>

              <ConversationTextFeedbackHistory
                feedback={isGlobalAdmin ? filteredFeedback : myFeedback ? [myFeedback] : []}
              />
              <div style={{ margin: '10px 0' }} />
              {/*<ConversationLikedMessages messages={data?.messages || []} showMessagesWith="like" />*/}
              {/*<div style={{ margin: '10px 0' }} />*/}
              {/*<ConversationLikedMessages messages={data?.messages || []} showMessagesWith="dislike" />*/}
            </>
          )}
          <ChatMessagesHistory
            messages={data?.messages || []}
            isLoading={isFetching}
            conversationId={conversationId || ''}
            allowLikeDislikeActions
            showRoles
            isScrollable={false}
            refetchConversation={refetch}
          />
        </div>
      </div>
    </div>
  )
}
