import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useGetFilterObservationsQuery } from '../../../../store/api/observation.api'
import './ObservationTab.less'
import { Image } from 'antd'
import ObservationActions from './ObservationActions'
import { useDispatch } from 'react-redux'
import { setSelectedObservation } from '../../../../store/slices/observation.slice'
import CollapsibleText from '../../../../components/CollapsibleText/CollapsibleText'

export const dateFormat = (date: Date) => {
  const newDate = new Date(date)
  return newDate.toLocaleDateString('en-US')
}

export const statusTitle = {
  addressed: 'Addressed',
  inProgress: 'In progress',
  notAddressed: 'Not addressed',
}

export default function ObservationTab() {
  const { projectId, status } = useParams()
  const dispatch = useDispatch()
  const statusKey = (status as keyof typeof statusTitle) || 'addressed'

  const [searchParams] = useSearchParams()

  const from = searchParams.get('from')
  const to = searchParams.get('to')
  const defaultDates = from && to ? [new Date(from), new Date(to)] : []

  const { data: observations, isLoading } = useGetFilterObservationsQuery({
    projectId: projectId || '',
    status: statusTitle[statusKey],
    pagination: {
      page: 1,
      pageSize: 10,
      startPeriod: defaultDates[0],
      finishPeriod: defaultDates[1],
    },
  })

  const datBackgroundColor = (status: string) =>
    status === 'Addressed' ? '#2C875D' : status === 'In progress' ? '#FFBF00' : '#FF0D31'

  return (
    <div>
      <div className="observation-list">
        {observations?.observations.map((observation) => (
          <div key={observation.id} className="observation-box">
            <div className="observation-box-top">
              <div className="observation-box-top-left">
                <div className="observation-box-top-left-name">
                  <div className="dot" style={{ backgroundColor: datBackgroundColor(observation.status) }} />
                  <p>{observation.name}</p>
                </div>
                <span className="date">{dateFormat(observation.createdAt)}</span>
              </div>
              <div className="observation-box-top-right">
                <div onClick={() => dispatch(setSelectedObservation(observation))}>
                  <ObservationActions observationId={observation.id} />
                </div>
              </div>
            </div>
            <div className="observation-hazards-identified">
              <p className="title">Hazards identified</p>
              <CollapsibleText text={observation.text} previewLength={200} />
              {observation.photoList.map((image) => (
                <Image
                  key={image}
                  src={image}
                  alt="preview"
                  style={{ height: 200, objectFit: 'cover' }}
                  preview={{
                    maskStyle: { backgroundColor: 'rgba(0, 0, 0, 0.9)' },
                  }}
                />
              ))}
              {observation.implementedActions && (
                <div className="observation-implemented-actions">
                  <p className="title">Implemented Actions</p>
                  <p className="text">{observation.implementedActions}</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
