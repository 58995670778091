import React, { LegacyRef, memo, useMemo } from 'react'
import remarkGfm from 'remark-gfm'
import rehypeSanitize from 'rehype-sanitize'
import ReactMarkdown from 'react-markdown'
import CustomMessage from '../CustomMessage/CustomMessage'
import { Message } from '../../../types/storage.type'
import LikeDislikeMessageButtons from '../../Feedback/LikeDislikeMessageButtons/LikeDislikeMessageButtons'

interface IProps {
  message: Message
  showRoles: boolean
  lastMessageRef?: LegacyRef<HTMLDivElement>
  lastUserMessageRef?: LegacyRef<HTMLDivElement>
  conversationId: string
  allowLikeDislikeActions: boolean
  refetchConversation: () => void
}

const ChatMessage = ({
  message,
  showRoles,
  lastMessageRef,
  lastUserMessageRef,
  conversationId,
  allowLikeDislikeActions,
  refetchConversation,
}: IProps) => {
  const isUserMessage = useMemo(() => {
    return message.role === 'user' && Array.isArray(message.content)
  }, [message])

  const currentMessage = useMemo(() => {
    return typeof message.content === 'string'
      ? message.content
      : JSON.stringify(message.content?.[0]?.text)?.slice(1, -1)
  }, [message])

  try {
    return (
      <div id={message.role + '-paragraph-container'} ref={lastUserMessageRef ?? lastMessageRef}>
        {showRoles && <h4>{message.role}</h4>}

        {message.tool_calls?.length ? (
          <div>
            {message.tool_calls.map((toolCall, index) => (
              <div className="paragraph function-call-paragraph" key={toolCall.id || index}>
                <h5>function_call {toolCall.function.name}</h5>
                <p>({toolCall.function?.arguments})</p>
              </div>
            ))}
          </div>
        ) : (
          <div id={String(message.id)}>
            <>
              {isUserMessage ? (
                <CustomMessage message={message}></CustomMessage>
              ) : (
                <>
                  {message.role === 'tool' && message.content?.length ? (
                    <div className="tool-content-container">
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                      {/*@ts-ignore*/}
                      {message.content?.map((item, index) => (
                        <div className="tool-content-item" key={item.id || index}>
                          <p>
                            <strong>Distance:</strong> {item.distance}
                          </p>
                          <p>
                            <strong>Text:</strong> {item.text}
                          </p>
                          {item.metadata?.url && (
                            <p>
                              <strong>Source:</strong>{' '}
                              <a href={item.metadata.url} target="_blank" rel="noopener noreferrer">
                                {item.metadata.url}
                              </a>
                            </p>
                          )}
                          {item.metadata?.images && (
                            <div>
                              <strong>Image:</strong>
                              <img
                                src={item.metadata.images}
                                alt="Metadata Image"
                                style={{ maxWidth: '100%', marginTop: '10px' }}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : null}

                  {message.content && (
                    <div id={'paragraph-' + message.role} className="paragraph">
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeSanitize]}
                        components={{
                          a: ({ href, children, ...props }) => (
                            <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
                              {children}
                            </a>
                          ),
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          code: ({ inline, className, children, ...props }) => {
                            const match = /language-(\w+)/.exec(className || '')
                            return !inline && match ? (
                              <pre>
                                <code className={`language-${match[1]}`} {...props}>
                                  {children}
                                </code>
                              </pre>
                            ) : (
                              <code className={className} {...props}>
                                {children}
                              </code>
                            )
                          },
                        }}
                      >
                        {currentMessage}
                      </ReactMarkdown>
                      {/*{allowLikeDislikeActions && message.role === 'assistant' && (*/}
                      {/*  <LikeDislikeMessageButtons*/}
                      {/*    feedback={message?.feedback}*/}
                      {/*    messageId={message.id}*/}
                      {/*    conversationId={conversationId}*/}
                      {/*    refetchConversation={refetchConversation}*/}
                      {/*  />*/}
                      {/*)}*/}
                    </div>
                  )}
                </>
              )}
            </>
          </div>
        )}
      </div>
    )
  } catch (error) {
    console.error('Markdown rendering failed:', error)
    // Return raw JSON as fallback
    return <pre>{JSON.stringify({ currentMessage }, null, 2)}</pre>
  }
}

export default memo(ChatMessage)

// TODO All messages content should be shown 187

// ):null
