import React, { useEffect, useRef, useState } from 'react'
import ChatMessageInput from './ChatMessageInput/ChatMessageInput'
import ChatMessagesHistory from './ChatMessagesHistory/ChatMessagesHistory'
import { useLocation, useParams } from 'react-router-dom'
import {
  useCreateConversationMutation,
  useLazyGetChatConfigQuery,
  useLazyGetConversationQuery,
  useSendChatMessageMutation,
} from '../../store/api/pubic.api'
import './chat.less'
import { notification, Typography } from 'antd'
import { GetChatRequest } from '../../types/public.type'
import {
  Conversation,
  ConversationImageUrl,
  ConversationMessageItem,
  ConversationMessageRequestBody,
} from '../../types/conversation.type'
import { useUploadFileMutation } from '../../store/api/uploads.api'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'
import { useAppSelector } from '../../hooks/appHook'
import { RolesEnum } from '../../config/rolesEnum'
import { setActiveChatConversationId } from '../../store/slices/chat.slice'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Message } from '../../types/storage.type'

const conversationInitialData = {
  agentId: '',
  accountId: '',
  analytics: undefined,
  createdAt: '',
  customerEmail: '',
  customerName: '',
  hasStorage: false,
  messages: [],
  storageId: '',
  unread: false,
  updatedAt: '',
  userName: '',
  userId: '',
}

const Chat = () => {
  const [isConversationHasStorage, setIsConversationHasStorage] = useState<boolean>(false)
  const { user } = useAppSelector((state) => state.appState)

  const location = useLocation()
  const dispatch = useDispatch()
  const { accountId } = useParams()
  const { t } = useTranslation('components')

  /** STORE */
  const { activeChatConversationId, activeChatAgentId } = useAppSelector((state) => state.chat)

  /** URL PARAMS */
  const getQueryParams = () => {
    const queryParams = new URLSearchParams(location.search)
    const storageId = queryParams.get('storageId') || ''
    const agentId = queryParams.get('agentId') || ''
    const conversationId = queryParams.get('conversationId') || ''
    const name = queryParams.get('name') || ''
    const email = queryParams.get('email') || ''
    return {
      storageId,
      agentId,
      conversationId,
      customerData:
        name && email
          ? {
              name,
              email,
            }
          : null,
    }
  }

  const removeQueryParams = () => {
    const queryParams = new URLSearchParams(location.search)

    const conversationId = queryParams.get('conversationId')

    const newQueryParams = new URLSearchParams()
    if (conversationId) {
      newQueryParams.set('conversationId', conversationId)
    }

    const newUrl = `${location.pathname}?${newQueryParams.toString()}`
    window.history.pushState({}, '', newUrl)
  }

  const setConversationIdParam = (conversationId: string) => {
    const currentUrl = window.location.href

    const url = new URL(currentUrl)
    url.searchParams.set('conversationId', conversationId)
    window.history.pushState({}, '', url)
  }

  /** STATE */
  const [conversation, setConversation] = useState<Conversation>({
    id: getQueryParams().conversationId,
    ...conversationInitialData,
  })
  const [priorityMessages, setPriorityMessages] = useState<Message[]>([])

  /** API */
  const [getConversationById, getConversationByIdResult] = useLazyGetConversationQuery()
  const [sendChatMessage, { isLoading: isMessageSending }] = useSendChatMessageMutation()
  const [uploadFile, uploadFileMutationResult] = useUploadFileMutation()
  // const [getChatConfig, { data: chatSettings, isFetching: isChatSettingFetching }] = useLazyGetChatConfigQuery()
  const [createConversation, createConversationResult] = useCreateConversationMutation()

  // const isRealtimeAllowed =
  //   user?.role == RolesEnum.ADMIN && isConversationHasStorage

  const isRealtimeAllowed = false

  /** REF */
  const lastMessageRef = useRef<HTMLDivElement | null>(null)
  const lastUserMessageRef = useRef<HTMLDivElement | null>(null)
  const chatRef = useRef<HTMLUListElement | null>(null)

  const isLoading =
    uploadFileMutationResult.isLoading ||
    createConversationResult.isLoading ||
    isMessageSending ||
    getConversationByIdResult.isLoading

  /** HANDLERS */
  const uploadImage = async (image: File, chatRequestProps: GetChatRequest) => {
    const formData = new FormData()
    formData.append('user', JSON.stringify(chatRequestProps))
    formData.append('file', image)

    const result: { data: ConversationImageUrl } | { error: FetchBaseQueryError | SerializedError } = await uploadFile({
      body: formData,
    })

    if ('data' in result) {
      return result
    }
    return null
  }

  const onSendChatMessage = async (values: ConversationMessageItem) => {
    // collect message START
    const chatRequestProps = {} as GetChatRequest

    const message: ConversationMessageRequestBody = {
      role: 'user',
      content: [],
    }

    if (values.message) {
      message.content.push({
        type: 'text',
        text: values.message,
      })
    }

    if (values.linksImages?.length) {
      values.linksImages.forEach((url: string) => {
        message.content.push({
          type: 'image_url',
          image_url: {
            url,
            s3Uri: '',
          },
        })
      })
    }

    let uploadsImageResult: Array<{ data: ConversationImageUrl }> = []

    if (values.fileImages?.length) {
      try {
        const uploadPromises = values.fileImages.map((image: File) => uploadImage(image, chatRequestProps))

        const results = await Promise.all(uploadPromises)

        uploadsImageResult = results.filter(Boolean) as Array<{ data: ConversationImageUrl }>
      } catch (err) {
        console.log('Uploads images error', err)
      }
    }

    if (uploadsImageResult?.length) {
      uploadsImageResult.forEach(({ data }) => {
        message.content.push({
          type: 'image_url',
          image_url: data,
        })
      })
    }

    // collect message FINISH

    let newConversationId = ''
    if (!conversation.id) {
      const newConversation = await createNewConversation()
      newConversationId = newConversation?.id || ''
    }

    let isMessageSent = false

    if (!conversation?.messages?.length) {
      setPriorityMessages([message as Message])
    }

    try {
      const data = await sendChatMessage({
        conversationId: newConversationId || conversation.id,
        message,
        language: user.language || 'English',
      })

      setPriorityMessages([])

      if ('data' in data && data.data.id) {
        setConversation(data.data)
        isMessageSent = true
      }
    } catch (error) {
      console.error('Error sending chat message:', error)
    }
    return isMessageSent
  }

  /** FUNCTIONS HELPERS */

  const createNewConversation = async () => {
    let newConversation: Conversation | undefined = undefined
    const { agentId, storageId, customerData } = getQueryParams()
    const result = await createConversation({
      accountId,
      agentId: agentId || activeChatAgentId || undefined,
      storageId,
      customerData,
    })
    if ('data' in result && result.data.id) {
      removeQueryParams()
      setConversation(result.data)
      newConversation = result.data
      setConversationIdParam(result.data.id)
      dispatch(setActiveChatConversationId(result.data.id))
    } else if ('error' in result) {
      const { error } = result as unknown as { error: { data: { message: string } } }
      notification.error({ message: error?.data?.message || t('chat.errorMessage') })
    }
    return newConversation
  }

  const fetchConversation = (conversationId?: string) => {
    const id = conversationId || conversation?.id

    if (id) {
      getConversationById({ conversationId: id }).then(({ data }) => {
        if (data?.id) {
          setConversation(data)
          scrollToLastMessage()
        }
      })
    }
  }

  const scrollToLastMessage = (lastMessage = lastMessageRef.current) => {
    setTimeout(() => {
      if (lastMessage) {
        console.log()
        ;(lastMessage?.id === lastMessageRef?.current?.id
          ? lastMessageRef.current
          : lastUserMessageRef.current
        )?.scrollIntoView({
          behavior: 'smooth',
          block: lastMessage?.id === lastMessageRef?.current?.id ? 'end' : 'start',
        })
      }
    }, 500)
  }

  /** Use EFFECT */
  useEffect(() => {
    fetchConversation()
  }, [])

  useEffect(() => {
    if (isMessageSending) {
      scrollToLastMessage()
    } else {
      scrollToLastMessage(lastUserMessageRef.current)
    }
  }, [isMessageSending])

  useEffect(() => {
    if (!conversation?.id) {
      return
    }
    setIsConversationHasStorage(!!conversation?.storageId || false)
  }, [conversation])

  useEffect(() => {
    if (activeChatConversationId === null) {
      setConversation({ ...conversationInitialData, id: '' })
      return
    }

    setConversationIdParam(activeChatConversationId)
    fetchConversation(activeChatConversationId)
  }, [activeChatConversationId])

  return (
    <section className="section-wrapper">
      <div className="chat-wrapper">
        <ChatMessagesHistory
          messages={priorityMessages?.length ? priorityMessages : conversation?.messages}
          lastMessageRef={lastMessageRef}
          lastUserMessageRef={lastUserMessageRef}
          chatRef={chatRef}
          isLoading={getConversationByIdResult.isFetching}
          conversationId={conversation.id}
          allowLikeDislikeActions
          refetchConversation={fetchConversation}
        />
        <ChatMessageInput
          messages={priorityMessages?.length ? priorityMessages : conversation?.messages}
          onSendChatMessage={onSendChatMessage}
          isLoading={isLoading}
          isRealtimeAllowed={isRealtimeAllowed}
          createConversation={createNewConversation}
          refetchMessages={fetchConversation}
          conversationId={conversation.id}
        />
        <Typography className="disclaimer-text">{t('chat.disclaimer')}</Typography>
      </div>
    </section>
  )
}

export default Chat
