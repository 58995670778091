import React from 'react'
import { Button, Modal, notification } from 'antd'
import { useDeleteProjectMutation } from '../../../../store/api/project.api'
import { useAppSelector } from '../../../../hooks/appHook'
import { useNavigate } from 'react-router-dom'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
}

const DeleteProjectModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate()
  const { project } = useAppSelector((state) => state.project)

  const [deleteProject, { isLoading: isUpdating }] = useDeleteProjectMutation()

  const isLoading = isUpdating

  const handleSubmit = async () => {
    try {
      deleteProject(project.id).unwrap()
      navigate('/projects')
      notification.success({ message: 'Project deleted successfully!' })
    } catch (error) {
      console.error('Failed to create project:', error)
      notification.error({ message: 'Failed to delete project. Please try again.' })
    }
  }

  return (
    <Modal
      title={`Delete Project`}
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmit}>
          Delete
        </Button>,
      ]}
    >
      {`Are you sure you want to delete the project `}
      <strong>{`${project.name}? `}</strong>
      {`This action cannot be undone`}
    </Modal>
  )
}

export default DeleteProjectModal
