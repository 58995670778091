import React, { useEffect, useState } from 'react'
import { Link, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Layout } from 'antd'
import Sidebar from './components/Sidebar/Sidebar'
import Header from './components/Header/Header'
import { useAppSelector } from '../../hooks/appHook'
import { AccountStatusEnum } from '../../types/account.type'
import { useGetAccountQuery } from '../../store/api/account.api'
import InviteToTeamModal from '../../components/UI/Modals/InviteToTeamModal'
import { ONBOARDING_ROUTE, SIGN_IN_ROUTE } from '../../config/consts'
import './mainLayout.less'
import Logo from '../../components/UI/Logos/Logo'
import ActionButtonsArea from './components/ActionButtonsArea/ActionButtonsArea'
import useWindowSize from '../../hooks/useWindowsSize'
import Loader from '../../components/UI/Loader/Loader'

const { Content } = Layout

const MainLayout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(true)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  /** Storage */
  const { user } = useAppSelector((state) => state.appState)
  const { account } = useAppSelector((state) => state.account)
  const { data, isLoading } = useGetAccountQuery(undefined, { skip: !user.id })

  const { isDesktop } = useWindowSize()

  const onToggleMenu = () => {
    setIsMenuOpen((pr) => !pr)
  }

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  useEffect(() => {
    document.body.style.overflow = 'auto'
  }, [])

  useEffect(() => {
    if (isEmpty(data)) {
      return
    }

    const isOnboarding = account.status === AccountStatusEnum.ONBOARD

    if (isOnboarding && location.pathname !== ONBOARDING_ROUTE) {
      return navigate(ONBOARDING_ROUTE)
    }
  }, [data, account])

  if (!user?.id) {
    return <Navigate to={SIGN_IN_ROUTE} state={{ from: location }} />
  }

  const menuBackground = `#022140`

  if (isLoading || isEmpty(account)) {
    return <Loader fullScreenHeight />
  }

  return (
    <div>
        <Layout id="ioni-main-layout">
          <InviteToTeamModal />
          {isDesktop && (
            <div
              className={isMenuOpen ? 'logo-wrapper' : 'logo-wrapper closed'}
              style={{ background: isMenuOpen ? 'transparent' : menuBackground }}
            >
              <Link to="/" className="logo">
                <Logo name="ioniBlack" width={'150'} height={'auto'} />
              </Link>
              <ActionButtonsArea
                isMenuOpen={isMenuOpen}
                onToggleMenu={onToggleMenu}
                toggleMobileMenu={toggleMobileMenu}
              />
            </div>
          )}
          <Sidebar
            isDesktopMenuOpen={isMenuOpen}
            isMobileMenuOpen={isMobileMenuOpen}
            toggleMobileMenu={toggleMobileMenu}
          />

          <Layout>
            <Header user={user} toggleMobileMenu={toggleMobileMenu} />
            <Content className="content">
              <Outlet />
            </Content>
          </Layout>
        </Layout>
    </div>
  )
}

export default MainLayout
