import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLazyGetConversationListQuery } from '../../../store/api/conversation.api'
import { useGetAgentListQuery } from '../../../store/api/agent.api'
import { List, Tooltip } from 'antd'
import './conversationsListMenu.less'
import { truncateText } from '../../../helpers/strings'
import { useAppSelector } from '../../../hooks/appHook'
import { setActiveChatConversationId } from '../../../store/slices/chat.slice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const ConversationsListMenu = () => {
  const [conversationsLimit, setConversationsLimit] = useState<number>(10)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  //*   STORE   */
  const { activeChatConversationId } = useAppSelector((state) => state.chat)
  const { account } = useAppSelector((state) => state.account)

  const queryParams = new URLSearchParams(location.search)

  //*   API   */
  const [getConversationsList, { data: conversationsListData, isFetching: isConversationsListFetching }] =
    useLazyGetConversationListQuery()

  //*   HELPERS   */
  const navigateToChatIfNotThere = () => {
    const targetPath = '/c/' + account?.id

    if (account?.id && !location.pathname.includes('/c/')) {
      navigate(targetPath)
    }
  }

  //*   HANDLERS   */
  // const onAgentClick = (agentId: string) => {
  //   setConversationsLimit(10)
  //   dispatch(setActiveChatAgentId(agentId))
  //   navigateToChatIfNotThere()
  // }

  const onConversationClick = (conversationId: string) => {
    dispatch(setActiveChatConversationId(conversationId))
    navigateToChatIfNotThere()
  }

  //*  useEffect  */

  useEffect(() => {
    const conversationId = queryParams.get('conversationId')
    if (conversationId) {
      dispatch(setActiveChatConversationId(conversationId))
    }
  }, [])

  const observer = useRef<IntersectionObserver | null>(null)

  const lastElementRef = useCallback(
    (node: HTMLElement) => {
      if (observer.current) {
        observer.current.disconnect()
      }

      observer.current = new IntersectionObserver((entries) => {
        if ((conversationsListData?.count || 0) > conversationsLimit && entries[0].isIntersecting) {
          setConversationsLimit((prevLimit) => prevLimit + 10)
        }
      })

      if (node) {
        observer.current.observe(node)
      }
    },
    [conversationsListData],
  )

  useEffect(() => {
    getConversationsList({
      pageSize: conversationsLimit,
    })
  }, [conversationsLimit])

  useEffect(() => {
    getConversationsList({
      pageSize: conversationsLimit,
    })
  }, [activeChatConversationId])

  return (
    <div className="conversations-list-wrapper">
      {/*<List*/}
      {/*  dataSource={agentsList}*/}
      {/*  loading={isAgentsListFetching}*/}
      {/*  className="agent-list"*/}
      {/*  split={false}*/}
      {/*  renderItem={(agent) => (*/}
      {/*    <List.Item*/}
      {/*      key={agent.id}*/}
      {/*      onClick={() => onAgentClick(agent.id)}*/}
      {/*      className={activeChatAgentId === agent.id ? 'agent-item selected-agent' : 'agent-item'}*/}
      {/*    >*/}
      {/*      {agent.name}*/}
      {/*    </List.Item>*/}
      {/*  )}*/}
      {/*/>*/}
      <List
        style={{ maxHeight: '300px', overflowX: 'hidden', paddingLeft: 0, marginTop: '20px' }}
        dataSource={conversationsListData?.conversations}
        loading={isConversationsListFetching}
        className="conversation-list"
        split={false}
        renderItem={(conversation, i) => (
          <List.Item
            key={conversation.id}
            onClick={() => onConversationClick(conversation.id || conversation._id || '')}
            className={activeChatConversationId === conversation.id ? 'selected-conversation' : 'conversation-item'}
            ref={(conversationsListData?.conversations.length ?? 0) === i + 1 ? lastElementRef : null}
          >
            <Tooltip title={conversation.name} placement="right">
              {truncateText(conversation?.name || conversation?.id || 'Not found', 34)}
            </Tooltip>
          </List.Item>
        )}
      />
    </div>
  )
}

export default ConversationsListMenu
