import React, { Suspense, useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { ConfigProvider } from 'antd'
import { StyleProvider } from '@ant-design/cssinjs'
import { theme } from './theme/theme'
import { IS_PRODUCTION } from './config/consts'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './components/AppRouter'
import '../src/config/i18next'
import initializeI18n from './config/i18next'
import { persistor } from './store/store'
import { PersistGate } from 'redux-persist/integration/react'
import Loader from './components/UI/Loader/Loader'

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

IS_PRODUCTION && ReactGA.initialize('G-2JFDHFP0Z1')

function App() {
  const [i18nInitialized, setI18nInitialized] = useState(false)

  useEffect(() => {
    initializeI18n().then(() => setI18nInitialized(true))
  }, [])

  if (!i18nInitialized) {
    return <Loader fullScreenHeight={true} />
  }

  return (
    <BrowserRouter>
      <StyleProvider hashPriority={'high'}>
        <ConfigProvider theme={theme}>
          <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={<Loader fullScreenHeight />}>
              <AppRouter />
            </Suspense>
          </PersistGate>
        </ConfigProvider>
      </StyleProvider>
    </BrowserRouter>
  )
}

export default App
