import React from 'react'
import { Button, Form, Input, Modal, notification } from 'antd'
import { useAppSelector } from '../../../../../hooks/appHook'
import { useDeleteObservationsMutation } from '../../../../../store/api/observation.api'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
}

const DeleteObservationModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const { selectedObservation } = useAppSelector((state) => state.observations)

  const [deleteObservation, { isLoading: isUpdating }] = useDeleteObservationsMutation()

  const isLoading = isUpdating

  const handleSubmit = async () => {
    try {
      await deleteObservation({ observationId: selectedObservation.id }).unwrap()
      onClose()
      notification.success({ message: 'Observation deleted successfully!' })
    } catch (error) {
      console.error('Failed to Delete observation:', error)
      notification.error({ message: 'Failed to Delete observation. Please try again.' })
    }
  }

  return (
    <Modal
      title="Rename observation"
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmit}>
          Delete
        </Button>,
      ]}
    >
      {`Are you sure you want to delete the observation`} <strong>{`${selectedObservation.name}`}</strong>
      {`? This action cannot be undone.`}
    </Modal>
  )
}

export default DeleteObservationModal
