import React from 'react'
import { Button, Modal, notification } from 'antd'
import { useArchiveProjectMutation } from '../../../../store/api/project.api'
import { useAppSelector } from '../../../../hooks/appHook'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
}

const ChangeStatusProjectModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const { project } = useAppSelector((state) => state.project)

  const statusProject = project.status === 'Active' ? 'Archived' : 'Active'
  const statusProjectText = project.status === 'Active' ? 'Archive' : 'Restore'

  const [archiveProject, { isLoading: isUpdating }] = useArchiveProjectMutation()

  const isLoading = isUpdating

  const handleSubmit = async () => {
    try {
      await archiveProject({ projectId: project.id, status: statusProject }).unwrap()
      onClose()
      notification.success({ message: 'Project status changed successfully!' })
    } catch (error) {
      console.error('Failed to change project status:', error)
      notification.error({ message: 'Failed to change project status. Please try again.' })
    }
  }

  return (
    <Modal
      title={`${statusProjectText} Project`}
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmit}>
          {statusProjectText}
        </Button>,
      ]}
    >
      {`Are you sure you want to ${statusProjectText} the project `}
      <strong>{`${project.name}?`}</strong>
    </Modal>
  )
}

export default ChangeStatusProjectModal
