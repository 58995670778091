import { Button } from 'antd'
import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'

const formatMarkdownLinks = (text: string) => {
  return (
    <ReactMarkdown
      components={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        a: ({ href, children }) => (
          <a href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        ),
      }}
    >
      {text}
    </ReactMarkdown>
  )
}

export default function CollapsibleText({ text, previewLength = 200 }: { text: string; previewLength: number }) {
  const [expanded, setExpanded] = useState(false)

  const previewText = text.slice(0, previewLength) + (text.length > previewLength ? '...' : '')

  return (
    <div>
      {!expanded ? (
        <>
          <p>{formatMarkdownLinks(previewText)}</p>
          {text.length > previewLength && (
            <Button type="link" onClick={() => setExpanded(true)}>
              Show More
            </Button>
          )}
        </>
      ) : (
        <>
          <p>{formatMarkdownLinks(text)}</p>
          <Button type="link" onClick={() => setExpanded(false)}>
            Show less
          </Button>
        </>
      )}
    </div>
  )
}
