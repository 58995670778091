import React from 'react'
import SignIn from '../routes/guest/SignIn'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useAppSelector } from '../hooks/appHook'
import MainLayout from '../layouts/mainLayout/MainLayout'
import GuestLayout from '../layouts/guestLayout/GuestLayout'
import Onboarding from '../routes/onboarding/Onboarding'
import Auth from '../routes/auth/Auth'
import ForgotPassword from '../routes/guest/ForgotPassword'
import InviteCreateSession from '../routes/guest/InviteCreateSession'
import NotFound from '../routes/guest/NotFound'
import Profile from '../routes/profile/Profile'
import ProfileEdit from '../routes/profile/ProfileEdit'
import Team from '../routes/accounts/components/Team'
import Conversations from '../routes/conversations/Conversations'
import Accounts from '../routes/accounts/Accounts'
import AIAgent from '../routes/aiAgent/AIAgent'
import KnowledgeBase from '../routes/knowledgeBase/KnowledgeBase'
import PublicLayout from '../layouts/publicLayout/PublicLayout'
import AddNewDataTab from '../routes/knowledgeBase/components/tabs/AddNewDataTab/AddNewDataTab'
import DocumentsTab from '../routes/knowledgeBase/components/tabs/DocumentsTab/DocumentsTab'
import { AddNewDataTabRoutes, KnowledgeBaseRoutes } from '../types/enums/knowledge-base-routes.enum'
import SearchAndEditTab from '../routes/knowledgeBase/components/tabs/SearchAndEditTab/SearchAndEditTab'
import ReviewAllDataTab from '../routes/knowledgeBase/components/tabs/ReviewAllDataTab/ReviewAllDataTab'
import SettingsTab from '../routes/knowledgeBase/components/tabs/SettingsTab/SettingsTab'
import AddByUrlTab from '../routes/knowledgeBase/components/tabs/AddNewDataTab/components/tabs/AddByUrlTab/AddByUrlTab'
import AddHtmlOrTextTab from '../routes/knowledgeBase/components/tabs/AddNewDataTab/components/tabs/AddHtmlOrTextTab/AddHtmlOrTextTab'
import AddFileTab from '../routes/knowledgeBase/components/tabs/AddNewDataTab/components/tabs/AddFileTab/AddFileTab'
import AddFileByUrlTab from '../routes/knowledgeBase/components/tabs/AddNewDataTab/components/tabs/AddFileByUrlTab/AddFileByUrlTab'
import ReviewAllDataByDocumentTab from '../routes/knowledgeBase/components/tabs/ReviewAllDataTab/ReviewAllDataByDocumentTab'
import Reports from '../routes/reports/Reports'
import Homepage from '../routes/homepage/Homepage'
import PrivateChatRoute from '../routes/privateChat/chat'
import PublicChatRoute from '../routes/public/PublicChat'
import { Conversation } from '../routes/conversations/components/Conversation/Conversation'
import Projects from '../routes/projects/Projects'
import Project from '../routes/project/Project'
import Observations from '../routes/profile/observations/Observations'
import ObservationTab from '../routes/profile/observations/observationTabs/ObservationTab'
import ObservationDetails from '../routes/profile/observations/ObservationDetails'

const AppRouter = () => {
  const { user } = useAppSelector((state) => state.appState)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const documentId = queryParams.get('documentId')

  return (
    <Routes>
      <Route path="/" element={user?.id ? <MainLayout /> : <Navigate replace to="/signin" />} />
      <Route element={<GuestLayout />}>
        <Route path="signin" element={user.id ? <Navigate to={'/'} /> : <SignIn />} />
        {/*<Route path="signup" element={user.id ? <Navigate to={'/'} /> : <SignUp />} />*/}
        <Route path="onboarding" element={<Onboarding />} />
        <Route path="auth" element={<Auth />} />
        <Route path="invite" element={<InviteCreateSession />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route index element={<Homepage />} />
        <Route path="storage" element={<KnowledgeBase />}>
          <Route path={KnowledgeBaseRoutes.addData} element={<AddNewDataTab />}>
            <Route path={AddNewDataTabRoutes.addHtmlOrText} element={<AddHtmlOrTextTab />} />
            <Route path={AddNewDataTabRoutes.addDocument} element={<AddFileTab />} />
            <Route path={AddNewDataTabRoutes.addDataByUrl} element={<AddByUrlTab />} />
            <Route path={AddNewDataTabRoutes.addDocumentByUrl} element={<AddFileByUrlTab />} />
          </Route>
          <Route path={KnowledgeBaseRoutes.documents} element={<DocumentsTab />} />
          <Route path={KnowledgeBaseRoutes.searchAndEdit} element={<SearchAndEditTab />} />
          <Route
            path={KnowledgeBaseRoutes.review}
            element={documentId ? <ReviewAllDataByDocumentTab /> : <ReviewAllDataTab />}
          />
          <Route path={KnowledgeBaseRoutes.settings} element={<SettingsTab />} />
        </Route>
        <Route path="profile" element={<Profile />} />
        <Route path="profile/edit" element={<ProfileEdit />} />
        <Route path="team" element={<Team />} />
        <Route path="agent" element={<AIAgent />} />
        <Route path={'projects'} element={<Projects />} />
        <Route path={'projects/:projectId'} element={<Project />}></Route>
        <Route
          path={'notifications'}
          element={
            <div
              style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}
            >
              <NotFound />
            </div>
          }
        />
        <Route path={'projects/:projectId/observations'} element={<Observations />}>
          <Route path={':status'} element={<ObservationTab />} />
          <Route path={':status/:observationId'} element={<ObservationDetails />} />
        </Route>

        <Route path="conversations" element={<Conversations />} />
        <Route path="conversations/:conversationId" element={<Conversation />} />
        <Route path="admin/accounts" element={<Accounts />} />
        <Route path="stats" element={<Reports />} />
      </Route>
      <Route element={user?.id ? <MainLayout /> : <PublicLayout />}>
        <Route path="/c/:accountId" element={user?.id ? <PrivateChatRoute /> : <PublicChatRoute />} />
      </Route>
    </Routes>
  )
}

export default AppRouter
