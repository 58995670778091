import React from 'react'
import { pinType } from '../../../types/observations'
import { StatusTitle } from '../../../types/observation.type'
import './pin.less'

interface IPinComponent {
  handleDoubleTap?: () => void
  handleOneTap?: () => void
  type?: pinType
  status?: StatusTitle | 'mix'
  currentScale?: number
  count?: number | undefined
  tip?: boolean
}

const statusTitle = {
  'Not addressed': '#FF0D31',
  'In progress': '#FFBF00',
  Addressed: '#2C875D',
  mix: '#5C9CCD',
}

export default function Pin({ handleDoubleTap, handleOneTap, status, currentScale = 1, count }: IPinComponent) {
  const isCount = count && count > 1
  return (
    <div className="pin-wrapper">
      <div
        className="pin"
        style={{
          margin: 5 / currentScale,
          width: 20 / currentScale,
          height: 20 / currentScale,
          backgroundColor: statusTitle[(status as StatusTitle) ?? 'addressed'],
        }}
      >
        {isCount && <p className="pin-count">{count}</p>}
      </div>
    </div>
  )
}
