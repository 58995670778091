import React from 'react'
import { Select } from 'antd'
import { useGetProjectsListQuery } from '../../../store/api/project.api'
import './SelectProject.less'
import { useNavigate, useParams } from 'react-router-dom'

const { Option } = Select

interface ISelectProject {
  status?: 'Archived' | 'Active' | undefined
  returnProjectId?: (id: string) => void
}

export default function SelectProject({ status, returnProjectId }: ISelectProject) {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const { data, isLoading } = useGetProjectsListQuery({
    status,
    rowsPerPage: 100,
  })

  const onChange = (id: string) => {
    if (returnProjectId) {
      returnProjectId(id)
    } else {
      navigate(`/projects/${id}`)
    }
  }

  return (
    <div className="select-project-box">
      <Select
        className="select-project"
        showSearch
        size="large"
        placeholder="Select a project"
        optionFilterProp="children"
        defaultValue={projectId}
        onChange={onChange}
      >
        {data?.projects.map((project) => (
          <>
            <Option value={project.id}>{project.name}</Option>
          </>
        ))}
      </Select>
    </div>
  )
}
