import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithReauth from '../baseQueryWithReauth'
// These types should be defined according to your project requirements
import { IProject, ICreateProjectPayload, IUpdateProjectPayload, IGetProjectsParams } from '../../types/project.type'
import { IUser } from '../../types/users.type'

export const projectApi = createApi({
  reducerPath: 'projectApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Projects', 'Members'],
  endpoints: (builder) => ({
    // Create a new project
    createProject: builder.mutation<IProject, ICreateProjectPayload>({
      query: (body) => ({
        url: 'projects',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Projects'],
    }),

    // Get many projects with pagination and search parameters
    getProjectsList: builder.query<{ projects: IProject[]; count: number }, IGetProjectsParams>({
      query: (params) => ({
        url: 'projects',
        method: 'GET',
        // You can pass any pagination or search parameters here
        params,
      }),
      providesTags: ['Projects'],
    }),

    // Get a single project by its id
    getProject: builder.query<IProject, string>({
      query: (projectId) => `projects/${projectId}`,
      providesTags: (result, error, projectId) => [{ type: 'Projects', id: projectId }],
    }),

    // Update a project
    updateProject: builder.mutation<IProject, { projectId: string; data: Partial<IUpdateProjectPayload> }>({
      query: ({ projectId, data }) => ({
        url: `projects/${projectId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'Projects' }],
    }),

    // Set who last opened the project
    setLastOpened: builder.mutation<IProject, { projectId: string }>({
      query: ({ projectId }) => ({
        url: `projects/last_opened_by/${projectId}`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, { projectId }) => [{ type: 'Projects', id: projectId }],
    }),

    getProjectMembers: builder.query<IUser[], string>({
      query: (projectId) => `projects/members/${projectId}`,
      providesTags: (result, error, projectId) => [{ type: 'Members', id: projectId }],
    }),
    updateProjectMembers: builder.mutation<IProject, { projectId: string; usersIds: string[] }>({
      query: ({ projectId, usersIds }) => ({
        url: `projects/members/${projectId}`,
        method: 'PATCH',
        body: { usersIds },
      }),
      invalidatesTags: (result, error, { projectId }) => [{ type: 'Members', id: projectId }],
    }),

    // Get the last opened project for a given user
    getLastOpenedProject: builder.query<IProject, string>({
      query: (userId) => `projects/last_opened_by/${userId}`,
      providesTags: ['Projects'],
    }),

    // Archive a project (or set it to Active)
    archiveProject: builder.mutation<IProject, { projectId: string; status: 'Active' | 'Archived' }>({
      query: ({ projectId, status }) => ({
        url: `projects/archived/${projectId}`,
        method: 'PATCH',
        body: { status },
      }),
      invalidatesTags: (result, error, { projectId }) => [{ type: 'Projects', id: projectId }],
    }),

    // Delete a project
    deleteProject: builder.mutation<{ message: string }, string>({
      query: (projectId) => ({
        url: `projects/${projectId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'Projects' }],
    }),
  }),
})

export const {
  useCreateProjectMutation,
  useGetProjectsListQuery,
  useGetProjectQuery,
  useUpdateProjectMutation,
  useSetLastOpenedMutation,
  useGetLastOpenedProjectQuery,
  useArchiveProjectMutation,
  useDeleteProjectMutation,
  useGetProjectMembersQuery,
  useUpdateProjectMembersMutation,
} = projectApi
