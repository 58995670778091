import React, { useState } from 'react'
import AiHelperHeader from '../../components/AIHelperHeader/aiHelperHeader'
import { Button, Card, Input, Pagination, Select, Tag } from 'antd'
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons'
import './projects.less'
import { useGetProjectsListQuery } from '../../store/api/project.api'
import Loader from '../../components/UI/Loader/Loader'
import CreateProjectModal from './CreateProjectModal/CreateProjectModal'
import { useAppSelector } from '../../hooks/appHook'
import { AccountRolesEnum, RolesEnum } from '../../config/rolesEnum'
import { Link } from 'react-router-dom'
import { formatDate } from '../../helpers/date'

const { Option } = Select
const Projects: React.FC = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState<string | undefined>(undefined)
  const [page, setPage] = useState(1)

  const { user } = useAppSelector((state) => state.appState)

  const hasPermissions = user?.role === RolesEnum.ADMIN || user?.accountRole === AccountRolesEnum.ADMIN

  // Call RTK Query hook with parameters
  const { data, isLoading } = useGetProjectsListQuery({
    searchQuery: search,
    status,
    page,
    rowsPerPage: 6,
    sortDirection: 'desc',
    sortBy: 'createdAt',
  })

  // Data returned from API
  const projects = data?.projects || []
  const totalCount = data?.count || 0

  // Handlers
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setPage(1) // Reset to first page on new search
  }

  const handleStatusChange = (value: string | undefined) => {
    setStatus(value)
    setPage(1) // Reset to first page on new filter
  }

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  }

  return (
    <>
      {isCreateModalOpen && (
        <CreateProjectModal isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} />
      )}
      <AiHelperHeader title="Project sitemap" paragraph="" />

      <div className="projects-container" style={{ padding: 16 }}>
        {/* Top toolbar: New Project button, search, and status filter */}
        <div className="side-bar" style={{ marginBottom: 16, display: 'flex', gap: 8 }}>
          {hasPermissions && (
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              className="add-new-button"
              onClick={() => setIsCreateModalOpen(true)}
            >
              New Project
            </Button>
          )}

          <Input
            placeholder="Search..."
            prefix={<SearchOutlined />}
            style={{ width: 200, marginLeft: 'auto' }}
            value={search}
            onChange={handleSearchChange}
          />

          <Select
            placeholder="Select status"
            style={{ width: 150 }}
            allowClear
            value={status}
            onChange={handleStatusChange}
          >
            <Option value="Active">Active</Option>
            <Option value="Archived">Archive</Option>
          </Select>
        </div>

        {/* Projects grid */}
        <div
          className="projects-grid"
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 16,
          }}
        >
          {isLoading ? (
            <Loader isLoading />
          ) : (
            projects.map((project) => {
              return (
                <Link key={project.id} to={`/projects/${project.id}`}>
                  <Card
                    key={project.id}
                    hoverable
                    cover={
                      <img alt={project.name} src={project.mainPhoto} style={{ height: 200, objectFit: 'cover' }} />
                    }
                  >
                    {/* Status Tag */}
                    <Tag color={project.status === 'Active' ? 'green' : 'volcano'}>{project.status}</Tag>
                    <h3 style={{ marginTop: 8 }}>
                      {project.name}
                      {project.projectNumber && <span style={{ color: '#757575' }}> #{project.projectNumber}</span>}
                    </h3>
                    <p style={{ color: '#999' }}>{formatDate(project.createdAt)}</p>
                  </Card>
                </Link>
              )
            })
          )}
        </div>

        {/* Pagination */}
        <div style={{ marginTop: 16, textAlign: 'center' }}>
          <Pagination current={page} pageSize={6} total={totalCount} onChange={handlePageChange} />
        </div>
      </div>
    </>
  )
}

export default Projects
