import React from 'react'
import { useGetProjectQuery } from '../../../store/api/project.api'
import ZoomableImage from '../../project/components/ZoomableImage'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from 'antd'

export default function ObservationDetails() {
  const navigation = useNavigate()
  const { projectId, observationId } = useParams()
  const { data: project } = useGetProjectQuery(projectId as string, {
    skip: !projectId,
  })

  return (
    <div style={{ width: '70%', paddingLeft: 20 }}>
      {project && (
        <ZoomableImage imageUrl={project?.mainPhoto} projectId={project?.id} selectedObservation={observationId} />
      )}
      <Button style={{ marginTop: 20 }} size="large" onClick={() => navigation(-1)}>
        Back to observations
      </Button>
    </div>
  )
}
