import React from 'react'
import { Collapse, Divider } from 'antd'
import './conversationTextFeedbackHistory.less'
import { ConversationFeedback } from '../../../types/conversation.type'
import { DislikeOutlined, LikeOutlined } from '@ant-design/icons'

const { Panel } = Collapse

type IProps = {
  feedback: ConversationFeedback[] | undefined
}

const ConversationTextFeedbackHistory = ({ feedback = [] }: IProps) => {
  if (!feedback || feedback.length === 0) {
    return <></>
  }

  return (
    <div className="conversation-text-feedback-history">
      <Collapse bordered={false} size="large" expandIconPosition="end">
        <Panel
          header={<h3 className="title">Feedback History</h3>}
          key="2"
          style={{ userSelect: 'none', position: 'relative' }}
        >
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {feedback.map((f, index) => (
              <React.Fragment key={index}>
                <li>
                  <div className="feedback-header">
                    {typeof f.userId === 'object' && (
                      <p className="from">
                        {f.rating === 'like' ? (
                          <LikeOutlined className="rating-icon" />
                        ) : (
                          <DislikeOutlined className="rating-icon" />
                        )}
                        <strong>Feedback from: </strong>
                        {f.userId?.email}
                      </p>
                    )}
                  </div>
                  <div className="feedback-details">
                    <div className="feedback-item">
                      <strong>What’s wrong with the LLM response? </strong>
                      <p>{f.whatIsWrong}</p>
                    </div>
                    <div className="feedback-item">
                      <strong>Why is it wrong? Include image details for the incorrect fragments.</strong>
                      <p>{f.whyWrong}</p>
                    </div>
                    <div className="feedback-item">
                      <strong>Correct response</strong>
                      <p>{f.correctResponse}</p>
                    </div>
                  </div>
                </li>
                {index < feedback.length - 1 && <Divider style={{ margin: '10px 0' }} />}
              </React.Fragment>
            ))}
          </ul>
        </Panel>
      </Collapse>
    </div>
  )
}

export default ConversationTextFeedbackHistory
