import React from 'react'
import Icons from '../../../../components/UI/Icons/Icons'
import useWindowSize from '../../../../hooks/useWindowsSize'
import { useLocation, useNavigate } from 'react-router-dom'
import { setActiveChatConversationId } from '../../../../store/slices/chat.slice'
import { useDispatch } from 'react-redux'
import "./actionButtonsArea.less"

interface IProps {
  isMenuOpen: boolean
  onToggleMenu: (() => void) | null
  toggleMobileMenu: (() => void) | null
}

const ActionButtonsArea = ({ isMenuOpen, onToggleMenu, toggleMobileMenu }: IProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const { isDesktop } = useWindowSize()

  const checkIsOnChatPath = () => {
    const location = useLocation()
    return location.pathname.startsWith('/c/')
  }

  const onNewChatClick = () => {
    dispatch(setActiveChatConversationId(null))
    // remove query params
    navigate(location.pathname)
  }

  return (
    <div className="icons-wrapper">
      <Icons
        className={checkIsOnChatPath() ? 'new-chat-icon' : 'new-chat-icon hidden'}
        onClick={onNewChatClick}
        name="newChat"
        width={22}
        height={21}
        fill={'white'}

      />
      {isDesktop ? (
        <Icons
          name="menu"
          className={isMenuOpen ? 'menu-icon open' : 'menu-icon'}
          onClick={() => onToggleMenu?.()}
          width={22}
          height={21}
          fill={'white'}
        />
      ) : (
        <Icons
          name="burgerMenu"
          onClick={() => toggleMobileMenu?.()}
          width={19}
          height={19}
        />
      )}
    </div>
  )
}

export default ActionButtonsArea
