import React from 'react'
import { Layout } from 'antd'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import './publicLayout.less'
import Logo from '../../components/UI/Logos/Logo'
import { useDispatch } from 'react-redux'
import { setActiveChatConversationId } from '../../store/slices/chat.slice'
import Icons from '../../components/UI/Icons/Icons'

const { Content } = Layout

const PublicLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const onNewChatClick = () => {
    dispatch(setActiveChatConversationId(null))
    // remove query params
    navigate(location.pathname)
  }

  const menuBackground = `linear-gradient(
  90deg,
  #e6ecf4 0%,
  #d5e2f2 20%,
  #d2e2f1 40%,
  #d6d7f4 60%,
  #e6d8f2 80%,
  #e4d1f2 100%
)`

  return (
    <Layout id="public-layout">
      <div className={'public-logo-wrapper'} style={{ background: menuBackground }}>
        <Link to="/" className="logo">
          <Logo name="ioniBlack" width={'150'} height={'auto'} />
        </Link>
        <div className="public-icons-wrapper">
          <Icons className={'public-new-chat-icon'} name="newChat" onClick={onNewChatClick} />
        </div>
      </div>
      <Content className="public-content">
        <Outlet />
      </Content>
    </Layout>
  )
}

export default PublicLayout
