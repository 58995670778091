import React, { useEffect, useMemo, useState } from 'react'
import { IPin } from '../../../types/observations'
import Pin from './Pin'
import { determineOverallStatus } from '../../../helpers/determineOverallStatus'
import Icons from '../../../components/UI/Icons/Icons'

interface IPinRender {
  pin?: IPin | null
  pinData: IPin[]
  editable?: boolean
  imageSize?: { width: number; height: number }
  currentScale?: number
  handleOnePressPin?: ({ x, y }: { x: number; y: number }) => void
}

export default function RenderedPins({
  pin,
  pinData,
  editable = true,
  imageSize = { width: 0, height: 0 },
  currentScale = 1,
  handleOnePressPin,
}: IPinRender) {
  const [pins, setPins] = useState<IPin[]>([])

  const groupPins = () => {
    const threshold = 0.05
    const groups: IPin[][] = []

    for (const pin of pins) {
      let foundGroup = false

      for (const group of groups) {
        if (
          group.some(
            (existingPin) => Math.abs(pin.x - existingPin.x) < threshold && Math.abs(pin.y - existingPin.y) < threshold,
          )
        ) {
          group.push(pin)
          foundGroup = true
          break
        }
      }

      if (!foundGroup) {
        groups.push([pin])
      }
    }

    // Convert groups into grouped pins
    return groups.map((group) => ({
      id: group[0].id,
      x: group.reduce((sum, p) => sum + p.x, 0) / group.length,
      y: group.reduce((sum, p) => sum + p.y, 0) / group.length,
      status: determineOverallStatus(group),
      count: group.length,
      type: group[0].type,
    }))
  }

  const getPins = () => {
    if (currentScale > 2) {
      return pins
    } else {
      return groupPins()
    }
  }

  const setHandleOnePressPin = async ({ x, y }: { x: number; y: number }) => {
    handleOnePressPin?.({ x, y })
    // await setValueStorage('showTip', 'true')
  }

  const allPins = pin ? [...getPins(), pin] : getPins()

  const renderedPins = useMemo(
    () =>
      allPins.map((pin, index) =>
        pin.type ? (
          <div
            key={index}
            style={{
              position: 'absolute',
              left: pin.x * imageSize.width - 25,
              top: pin.y * imageSize.height - 30,
            }}
          >
            <Icons name="pin" />
          </div>
        ) : (
          <div
            key={index}
            style={{
              position: 'absolute',
              left: pin.x * imageSize.width - 10,
              top: pin.y * imageSize.height - 10,
            }}
          >
            <Pin count={pin.count} status={pin.status} currentScale={currentScale} />
          </div>
        ),
      ),
    [pins, imageSize, editable],
  )

  useEffect(() => {
    if (pinData.length) {
      setPins(pinData)
    } else {
      setPins([])
    }
  }, [pinData])

  useEffect(() => {
    if (pin?.x) {
      setPins(pinData)
    }
  }, [pin?.x])

  return <>{renderedPins}</>
}
