import React, { useState } from 'react'
import { Button, Form, Input, Modal, notification, Select, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useCreateProjectMutation } from '../../../store/api/project.api'
import { useUploadFileMutation } from '../../../store/api/uploads.api'
import { useGetAllAccountsNamesQuery } from '../../../store/api/account.api'
import { useAppSelector } from '../../../hooks/appHook'
import { AccountRolesEnum, RolesEnum } from '../../../config/rolesEnum'
import { IUser } from '../../../types/users.type'
import UsersSelect from '../../../components/UsersSelect/UsersSelect'

interface CreateProjectModalProps {
  isOpen: boolean
  onClose: () => void
}

const CreateProjectModal: React.FC<CreateProjectModalProps> = ({ isOpen, onClose }) => {
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([])
  const [form] = Form.useForm()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [fileList, setFileList] = useState<any[]>([])
  const { user } = useAppSelector((state) => state.appState)

  const [createProject, { isLoading: isCreating }] = useCreateProjectMutation()
  const [uploadFile, { isLoading: isUploading }] = useUploadFileMutation()
  const { data: accounts, isFetching: isAccountsLoading } = useGetAllAccountsNamesQuery()

  const isLoading = isCreating || isUploading || isAccountsLoading

  const handleFileChange = (info: any) => {
    const newFileList = info.fileList.slice(-1)
    setFileList(newFileList)
    setSelectedFile(newFileList.length > 0 ? newFileList[0].originFileObj : null)
  }

  const isAdminAdmin = user.role === RolesEnum.ADMIN && user.accountRole === AccountRolesEnum.ADMIN
  const isAnyAdmin = user.role === RolesEnum.ADMIN || user.accountRole === AccountRolesEnum.ADMIN

  const handleSubmit = async () => {
    try {
      // Validate the form fields
      const values = await form.validateFields()

      // Ensure a file is selected
      if (!selectedFile) {
        notification.error({ message: 'Please select an image file for Main Photo.' })
        return
      }

      // Prepare the file upload via FormData
      const formData = new FormData()
      formData.append('file', selectedFile)

      // Upload the file and extract the URL from the response
      const uploadResponse = await uploadFile({ body: formData }).unwrap()
      const uploadedUrl = uploadResponse.url

      // Build the project payload including the uploaded image URL
      const projectPayload = {
        ...values,
        mainPhoto: uploadedUrl,
        usersIds: selectedUsers.map((user) => user.id),
      }

      // Create the project with the payload
      await createProject(projectPayload).unwrap()

      // Reset form and state, then close the modal
      form.resetFields()
      setSelectedFile(null)
      setFileList([])
      onClose()
      notification.success({ message: 'Project created successfully!' })
    } catch (error) {
      console.error('Failed to create project:', error)
      notification.error({ message: 'Failed to create project. Please try again.' })
    }
  }

  return (
    <Modal
      title="Create New Project"
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmit}>
          Create
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input the project name!' }]}>
          <Input placeholder="Project name" />
        </Form.Item>
        <Form.Item label="Country" name="country" rules={[{ required: true, message: 'Please input the country!' }]}>
          <Input placeholder="Country" />
        </Form.Item>
        <Form.Item label="City" name="city" rules={[{ required: true, message: 'Please input the city!' }]}>
          <Input placeholder="City" />
        </Form.Item>
        <Form.Item label="Address" name="address" rules={[{ required: true, message: 'Please input the address!' }]}>
          <Input placeholder="Address" />
        </Form.Item>
        <Form.Item label="Zip Code" name="zipCode" rules={[{ required: true, message: 'Please input the zip code!' }]}>
          <Input placeholder="Zip Code" />
        </Form.Item>
        {/* Use Upload component for the image input */}
        <Form.Item label="Main Photo">
          <Upload
            fileList={fileList}
            beforeUpload={() => false} // Prevent automatic upload
            onChange={handleFileChange}
            onRemove={() => {
              setFileList([])
              setSelectedFile(null)
            }}
            accept="image/*"
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Select Image</Button>
          </Upload>
        </Form.Item>
        {/* Updated Company input: now a Select with options from the accounts variable */}
        {isAdminAdmin && (
          <Form.Item
            label="Company"
            name="accountId"
            rules={[{ required: true, message: 'Please select an account!' }]}
          >
            <Select placeholder="Select Company">
              {accounts?.map((account) => (
                <Select.Option key={account.id} value={account.id}>
                  {account.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="Project Number" name="projectNumber">
          <Input placeholder="Project Number" />
        </Form.Item>
        {/*TODO uncomment*/}
        {/*{isAnyAdmin && (*/}
        {/*  <Form.Item label="Project members" name="ProjectMembers">*/}
        {/*    <UsersSelect selectedUsers={selectedUsers} setSelectedUsers={(users: IUser[]) => setSelectedUsers(users)} />*/}
        {/*  </Form.Item>*/}
        {/*)}*/}
      </Form>
    </Modal>
  )
}

export default CreateProjectModal
