import React, { useEffect, useState } from 'react'
import { Form, Input } from 'antd'
import './conversationTextFeedback.less'
import AppPrimaryButton from '../../UI/AppPrimaryButton/AppPrimaryButton'
import { CaretDownOutlined, CaretUpOutlined, DislikeOutlined, LikeOutlined } from '@ant-design/icons'
import { useEditConversationMutation } from '../../../store/api/pubic.api'
import { useTranslation } from 'react-i18next'
import { useGetConversationByIdQuery } from '../../../store/api/conversation.api'

type IProps = {
  conversationId: string
}

type Rating = 'like' | 'dislike'

const ConversationTextFeedback = ({ conversationId }: IProps) => {
  const [isOpen, setIsOpen] = useState(true)
  const [whatIsWrong, setWhatIsWrong] = useState('')
  const [whyWrong, setWhyWrong] = useState('')
  const [correctResponse, setCorrectResponse] = useState('')
  const [rating, setRating] = useState<Rating | null>(null)

  const conversationT = useTranslation('conversations')

  // API
  const [sendConversationFeedback, sendConversationFeedbackResult] = useEditConversationMutation()
  const { refetch } = useGetConversationByIdQuery({ id: conversationId || '' })

  // Handlers for feedback fields
  const handleWhatIsWrongChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setWhatIsWrong(e.target.value)
  }

  const handleWhyWrongChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setWhyWrong(e.target.value)
  }

  const handleCorrectResponseChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCorrectResponse(e.target.value)
  }

  const onSubmit = () => {
    if (rating && whatIsWrong && whyWrong && correctResponse) {
      sendConversationFeedback({
        id: conversationId,
        body: {
          conversationFeedback: {
            rating,
            whatIsWrong,
            whyWrong,
            correctResponse,
          },
        },
      })
    }
  }

  useEffect(() => {
    if (sendConversationFeedbackResult.isSuccess) {
      setWhatIsWrong('')
      setWhyWrong('')
      setCorrectResponse('')
      refetch()
    }
  }, [sendConversationFeedbackResult.isSuccess])

  return (
    <Form
      layout="vertical"
      className={isOpen ? 'conversation-text-feedback feedback-form-open' : 'conversation-text-feedback'}
      onFinish={onSubmit}
    >
      <div className="feedback-header">
        <h4 className="title" onClick={() => setIsOpen((v) => !v)}>
          {conversationT.t('conversation.conversationTextFeedback.title')}
          {isOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
        </h4>
      </div>

      {isOpen && (
        <>
          <div className="rating-wrapper">
            <LikeOutlined
              className={rating === 'like' ? 'rating-icon selected' : 'rating-icon'}
              onClick={() => setRating('like')}
            />
            <DislikeOutlined
              className={rating === 'dislike' ? 'rating-icon selected' : 'rating-icon'}
              onClick={() => setRating('dislike')}
            />
          </div>

          <Form.Item label="What’s wrong with the observation? (quote word-for-word)" required>
            <Input.TextArea
              placeholder="e.g. Chatbot states that the electric cord wasn’t properly attached to the battery on the aerial lift. This is incorrect."
              value={whatIsWrong}
              onChange={handleWhatIsWrongChange}
              rows={5}
              style={{
                marginBottom: '10px',
                padding: '10px',
                borderRadius: '5px',
                resize: 'vertical',
              }}
            />
          </Form.Item>

          <Form.Item
            label="Why is it wrong? Describe image objects that may have been misinterpreted/caused wrong observation."
            required
          >
            <Input.TextArea
              placeholder="e.g. The black box with the red logo on the floor of the aerial lift isn’t a battery - it’s a toolbox. The white cable with black dots is not an electric cord - it’s a scaffolding rope."
              value={whyWrong}
              onChange={handleWhyWrongChange}
              rows={5}
              style={{
                marginBottom: '10px',
                padding: '10px',
                borderRadius: '5px',
                resize: 'vertical',
              }}
            />
          </Form.Item>

          <Form.Item label="Suggest the most optimal response for this case." required>
            <Input.TextArea
              placeholder="e.g. Therefore, there are no OSHA regulations governing this case, making this scenario non-violative."
              value={correctResponse}
              onChange={handleCorrectResponseChange}
              rows={5}
              style={{
                marginBottom: '10px',
                padding: '10px',
                borderRadius: '5px',
                resize: 'vertical',
              }}
            />
          </Form.Item>

          <AppPrimaryButton
            type="primary"
            htmlType="submit"
            className="submit-button"
            size="middle"
            disabled={!whatIsWrong || !whyWrong || !correctResponse || !rating}
            loading={sendConversationFeedbackResult.isLoading}
          >
            {conversationT.t('conversation.conversationTextFeedback.submit')}
          </AppPrimaryButton>
        </>
      )}
    </Form>
  )
}

export default ConversationTextFeedback
