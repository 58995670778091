import { Button, Dropdown, Menu, Row } from 'antd'
import React, { useState } from 'react'
import Icons from '../../../../components/UI/Icons/Icons'
import RenameObservationModal from './observationModals/EditName'
import ChangeStatusObservationModal from './observationModals/ChangeStatus'
import DeleteObservationModal from './observationModals/Delete'
import './ObservationActions.less'
import { useNavigate } from 'react-router-dom'

export default function ObservationActions({ observationId }: { observationId: string }) {
  const navigation = useNavigate()
  const [isRenameModalOpen, setRenameModalOpen] = useState(false)
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const menu = (
    <Menu>
      <Menu.Item className="menu-item" onClick={() => setRenameModalOpen(true)}>
        <Icons name="pencil" />
        Rename
      </Menu.Item>
      <Menu.Item className="menu-item" onClick={() => setIsChangeStatusModalOpen(true)}>
        <Icons name="change" />
        Change status
      </Menu.Item>
      <Menu.Item className="menu-item" onClick={() => setIsDeleteModalOpen(true)}>
        <Icons name="cross" />
        Delete
      </Menu.Item>
    </Menu>
  )
  return (
    <div>
      <Row>
        <Button type="ghost" className="open-actions-button" onClick={(e) => navigation(observationId)}>
          <Icons name="mapLocation" />
        </Button>
        <Dropdown overlay={menu}>
          <Button type="ghost" className="open-actions-button" onClick={(e) => e.preventDefault()}>
            <Icons name="threeDots" />
          </Button>
        </Dropdown>
      </Row>
      {isRenameModalOpen && (
        <RenameObservationModal isOpen={isRenameModalOpen} onClose={() => setRenameModalOpen(false)} />
      )}
      {isChangeStatusModalOpen && (
        <ChangeStatusObservationModal
          isOpen={isChangeStatusModalOpen}
          onClose={() => setIsChangeStatusModalOpen(false)}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteObservationModal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} />
      )}
    </div>
  )
}
