import React, { memo } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Logo from '../../components/UI/Logos/Logo'
import TransparentButton from '../../components/UI/TransparentButton/TransparentButton'

import { useTranslation } from 'react-i18next'


const TopFloater = () => {

  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation('guest')


  const isSignin = location.pathname.includes('signin')

  const handleAuth = () => {
    if (isSignin) {
      window.open('https://www.safetylens.ai/#Contact-Form', '_blank', 'noopener,noreferrer')
    } else {
      navigate('/signin')
    }
  }

  return (
    <div className="top-floater">
      <Link to="/" target="_blank" className="logo-container" rel="noreferrer">
        <Logo name="slBlack" width={'150'} height={'auto'} />
      </Link>
      <div className="top-floater-right">
        <div className="top-floater-right-text">
          {isSignin ? t('topFloater.dontHaveAccountYet') : t('topFloater.alreadyHaveAccount')}
        </div>
        <TransparentButton title={isSignin ? t('topFloater.signUp') : t('topFloater.signIn')} onClick={handleAuth} />
      </div>
    </div>
  )
}

export default memo(TopFloater)
