import React, { useState } from 'react'
import { Button } from 'antd'
import Icons from '../../../components/UI/Icons/Icons'
import EditProjectModal from './actionModals/EditProjectModal'
import ChangeStatusProjectModal from './actionModals/ChageStatuseProjectModal'
import { useAppSelector } from '../../../hooks/appHook'
import DeleteProjectModal from './actionModals/DeleteProjectModal'
import './ActionButtons.less'
import { useParams } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons'
import EditProjectMembersModal from '../../projects/EditProjectMembersModal/EditProjectMembersModal'
import { AccountRolesEnum, RolesEnum } from '../../../config/rolesEnum'

export default function ProjectActionButtons() {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isChangeStatusProjectModalOpen, setIsChangeStatusProjectModalOpen] = useState(false)
  const [isDeleteProjectModalOpen, setIsDeleteProjectModalOpen] = useState(false)
  const [isMembersModalOpen, setIsMembersModalOpen] = useState(false)
  const { project } = useAppSelector((state) => state.project)
  const { user } = useAppSelector((state) => state.appState)

  const hasPermissions = user.role === RolesEnum.ADMIN || user.accountRole === AccountRolesEnum.ADMIN

  const statusProjectText = project.status === 'Active' ? 'Archive' : 'Restore'
  const { projectId } = useParams()
  return (
    <div>
      <div className="action-box">
        {/*TODO uncomment*/}
        {/*<Button size="large" onClick={() => setIsMembersModalOpen(true)}>*/}
        {/*  <div className="action-box-button">*/}
        {/*    <UserOutlined />*/}
        {/*    Members*/}
        {/*  </div>*/}
        {/*</Button>*/}
        <Button size="large" onClick={() => setIsEditModalOpen(true)}>
          <div className="action-box-button">
            <Icons name="pencil" />
            Edit project
          </div>
        </Button>
        <Button size="large" onClick={() => setIsChangeStatusProjectModalOpen(true)}>
          <div className="action-box-button">
            <Icons name="archive" />
            {statusProjectText}
          </div>
        </Button>
        <Button size="large" onClick={() => setIsDeleteProjectModalOpen(true)}>
          <div className="action-box-button">
            <Icons name="trash" />
            Delete
          </div>
        </Button>
      </div>
      {isEditModalOpen && <EditProjectModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} />}
      {isChangeStatusProjectModalOpen && (
        <ChangeStatusProjectModal
          isOpen={isChangeStatusProjectModalOpen}
          onClose={() => setIsChangeStatusProjectModalOpen(false)}
        />
      )}
      {isDeleteProjectModalOpen && (
        <DeleteProjectModal isOpen={isDeleteProjectModalOpen} onClose={() => setIsDeleteProjectModalOpen(false)} />
      )}
      {/*TODO uncomment*/}
      {/*{isMembersModalOpen && projectId && hasPermissions && (*/}
      {/*  <EditProjectMembersModal projectId={projectId} onClose={() => setIsMembersModalOpen(false)} />*/}
      {/*)}*/}
    </div>
  )
}
